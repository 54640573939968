import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Image, Text, Tag, Icon } from "../components";
import { BaseColor, useTheme } from "../config";
import StarRating from "./StarRating";
import { useTranslation } from "react-i18next";

export default function ProfileAuthor(props: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    style,
    image,
    styleLeft,
    styleThumb,
    styleRight,
    onPress,
    name,
    rating,
    reviews,
    textRight,
    tags,
    onShare,
    onEdit,
  } = props;
  return (
    <>
      <TouchableOpacity
        style={[styles.contain, style]}
        onPress={onPress}
        activeOpacity={0.9}
      >
        <View style={[styles.contentLeft, styleLeft]}>
          <View style={{ flexDirection: "column" }}>
            <Image source={image} style={[styles.thumb, styleThumb]} />
            <Text
              headline
              semibold
              numberOfLines={1}
              style={{ textAlign: "center", marginTop: 3 }}
            >
              {name}
            </Text>
          </View>
          <View style={{ marginTop: 25 }}>
            <View style={{ width: 80 }}>
              <StarRating
                disabled={true}
                starSize={16}
                maxStars={5}
                rating={rating}
                selectedStar={(rating: any) => {}}
                fullStarColor={BaseColor.yellowColor}
              />
            </View>
            <View style={{ marginTop: 5 }}>
              <Text>
                {rating} · {reviews} {t("sitter_profile:rating_and_reviews")}
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.contentRight, styleRight, { marginTop: 20 }]}>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity onPress={onShare}>
              <Icon
                style={{ marginHorizontal: 10 }}
                name="share-square"
                size={20}
                solid
                color={colors.accent}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={onEdit}>
              <Icon
                style={{ marginHorizontal: 10 }}
                name="cog"
                size={20}
                color={colors.accent}
              />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
      <View style={[styles.listContentService, { marginBottom: 10 }]}>
        {tags.map((item: any, index: any) => {
          return (
            <Tag
              key={"service" + index}
              icon={
                <Icon
                  name={item.icon}
                  size={12}
                  color={colors.accent}
                  solid
                  style={{ marginRight: 5 }}
                />
              }
              chip
              style={{
                marginTop: 5,
                marginRight: 5,
              }}
            >
              {t(`license:${item.name}`)}
            </Tag>
          );
        })}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
    paddingVertical: 10,
  },
  contentLeft: {
    flex: 8,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  thumb: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginRight: 5,
    borderColor: "white",
    borderWidth: 3,
  },
  contentRight: {
    flex: 4,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  listContentService: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
