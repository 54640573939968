import React, { useState } from "react";
import {
  View,
  ScrollView,
  Animated,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  Share,
} from "react-native";
import { BaseColor, Images, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  Text,
  ProfileAuthor,
  Card,
  ServiceItem,
  Tag,
} from "../../components";
import * as Utils from "../../utils";
import { useTranslation } from "react-i18next";
import serviceData from "../../fakedata/serviceData";
import { SearchSitter } from "../../fakedata";

export default function PostDetail({ navigation }: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const deltaY = new Animated.Value(0);
  const heightHeader = Utils.heightHeader();
  const heightImageBanner = Utils.scaleWithPixel(200);
  const marginTopBanner = heightImageBanner - heightHeader - 30;

  const [showMoreDescription, setShowMoreDescription] = useState(false);

  return (
    <View style={{ flex: 1 }}>
      <Animated.Image
        source={Images.banners.banner1}
        style={[
          styles.imgBanner,
          {
            height: deltaY.interpolate({
              inputRange: [
                0,
                Utils.scaleWithPixel(100),
                Utils.scaleWithPixel(100),
              ],
              outputRange: [heightImageBanner, heightHeader, heightHeader],
            }),
          },
        ]}
      />
      <Header
        title=""
        renderLeft={() => {
          return (
            <Icon name="arrow-left" size={20} color={colors.primaryDark} />
          );
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
      />
      <SafeAreaView style={{ flex: 1 }} edges={["right", "left", "bottom"]}>
        <ScrollView
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: { y: deltaY },
                },
              },
            ],
            {
              useNativeDriver: false,
            },
          )}
          scrollEventThrottle={8}
        >
          <View
            style={{
              paddingHorizontal: 20,
              marginBottom: 20,
              marginTop: marginTopBanner,
            }}
          >
            <ProfileAuthor
              image={SearchSitter[0].image}
              name={SearchSitter[0].name}
              rating={SearchSitter[0].rate}
              reviews={SearchSitter[0].numReviews}
              textRight="2022/06/10"
              style={{
                marginTop: -20,
              }}
              tags={SearchSitter[0].tags}
              onEdit={() => {
                navigation.navigate("SitterProfileEdit");
              }}
              onShare={async () => {
                try {
                  const result = await Share.share({
                    message:
                      "React Native | A framework for building native apps using React",
                  });
                  if (result.action === Share.sharedAction) {
                    if (result.activityType) {
                      // shared with activity type of result.activityType
                    } else {
                      // shared
                    }
                  } else if (result.action === Share.dismissedAction) {
                    // dismissed
                  }
                } catch (error) {}
              }}
            />
            <View
              style={{
                marginBottom: 20,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Tag
                icon={
                  <Icon
                    name="wallet"
                    size={18}
                    color={colors.accent}
                    solid
                    style={{ marginRight: 5 }}
                  />
                }
                style={styles.wallet}
                textStyle={{
                  fontSize: 16,
                }}
                onPress={() => {
                  navigation.navigate("SitterWallet");
                }}
              >
                {t("sitter_profile:wallet")}
              </Tag>
            </View>
            <Text body2 numberOfLines={showMoreDescription ? undefined : 4}>
              犬の飼育に30年以上、猫の飼育に6年以上の経験があり、ネズミ（数世代）、鳥、ウサギ、魚、カメなども飼育しています。
              現在、家に​​は猫が6匹、カメが1匹いますが、理解しているので、ペットの不快感を見つけてすぐに治療を求めることができます。子供の頃から小動物が大好きなので、やりたいです。ペット関連の仕事に従事します。
            </Text>
            <TouchableOpacity
              onPress={() => {
                setShowMoreDescription(!showMoreDescription);
              }}
            >
              <Text darkPrimaryColor style={{ marginTop: 15 }}>
                {showMoreDescription ? (
                  <>{t("enum:show_less")}</>
                ) : (
                  <>{t("enum:show_more")}</>
                )}
              </Text>
            </TouchableOpacity>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <Text
                headline
                semibold
                style={{
                  marginBottom: 10,
                }}
              >
                {t("sitter_profile:service_list")}
              </Text>
              <TouchableOpacity onPress={() => navigation.navigate("Post")}>
                <Text footnote grayColor>
                  {t("sitter_profile:add_service")}
                </Text>
              </TouchableOpacity>
            </View>
            <FlatList
              contentContainerStyle={{ paddingLeft: 5, paddingRight: 20 }}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={serviceData}
              keyExtractor={(item, index) => item.id}
              renderItem={({ item, index }) => (
                <ServiceItem
                  grid
                  style={[styles.serviceItem, { marginLeft: 15 }]}
                  onPress={() => {
                    navigation.navigate("ServiceDetail");
                  }}
                  image={item.image}
                  name={item.name}
                  location={item.location}
                  travelTime={item.location}
                  startTime={item.startTime}
                  price={item.price}
                  rate={item.rate}
                  rateCount={item.rateCount}
                  numReviews={item.numReviews}
                  author={item.author}
                  tags={item.tags}
                />
              )}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <Text headline semibold>
                {t("sitter_profile:my_service_photo")}
              </Text>
              <TouchableOpacity onPress={() => navigation.navigate("Post")}>
                <Text footnote grayColor>
                  {t("sitter_profile:upload_image")}
                </Text>
              </TouchableOpacity>
            </View>
            {/* Image gallery */}
            <View style={styles.contentImageFollowing}>
              <View style={{ flex: 4, marginRight: 10 }}>
                <Card image={Images.services.service2} />
              </View>
              <View style={{ flex: 6 }}>
                <View style={{ flex: 1 }}>
                  <Card image={Images.services.service3} />
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <View style={{ flex: 6, marginRight: 10 }}>
                    <Card image={Images.services.service6} />
                  </View>
                  <View style={{ flex: 4 }}>
                    <Card image={Images.services.service7} />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  imgBanner: {
    width: "100%",
    height: 250,
    position: "absolute",
  },
  contentImageFollowing: {
    flexDirection: "row",
    height: Utils.scaleWithPixel(160),
    marginTop: 10,
  },
  serviceItem: {
    width: 160,
  },
  wallet: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    marginRight: 5,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
});
