export default [
  {
    id: "1",
    name: "Skye",
    avatarId: "https://imgur.com/KHgIT7n.jpg", // firebase upload image
    birthYear: "2014",
    category: 1,
    gender: 1,
    healthStatus: `彼女は1日2回歩く必要があります。\n彼女は少し食べ物を持っています。\n彼女は寄り添うのが好きで、大きな音が嫌いです`,
    neuteredOrSpayed: false, //有無結紮
    personalities: [], //寵物個性
    regularVaccination: false, //有無接種疫苗
    size: 1,
  },
];
