import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Text, Image } from "../components";
import { useTheme, Images } from "../config";
import { useTranslation } from "react-i18next";

export default function PetItem(props: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { style, name, avatarId, healthStatus, onPress, category } = props;

  return (
    <TouchableOpacity
      style={[styles.contain, { shadowColor: colors.border }, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginVertical: 5,
        }}
      >
        <View style={{ flexDirection: "column", width: "100%" }}>
          <View
            style={[
              styles.nameContent,
              {
                borderBottomColor: colors.card,
                backgroundColor: colors.accent,
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <Text body2 whiteColor semibold>
                {name}
              </Text>
              <View style={{ marginLeft: 10 }}>
                <Text body2 whiteColor semibold>
                  {t(
                    `pets:emoji_${category === 1 ? "category_DOG" : "category_CAT"}`,
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.mainContent,
              {
                backgroundColor: colors.accent,
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
              },
            ]}
          >
            <View style={{ flex: 2 }}>
              <Image
                source={{ uri: avatarId }}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  marginRight: 10,
                }}
                resizeMode={"cover"}
              />
            </View>
            <View style={{ flexDirection: "column", flex: 4 }}>
              <Text caption2 whiteColor style={{ marginBottom: 5 }}>
                {t("pets:health_status")}
              </Text>
              <Text
                body2
                whiteColor
                semibold
                numberOfLines={3}
                ellipsizeMode={"head"}
                style={{ width: "100%" }}
              >
                {healthStatus}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 1.0,
    elevation: 5,
  },
  nameContent: {
    alignItems: "flex-start",
    borderBottomWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  validContent: {
    flexDirection: "row",
    paddingHorizontal: 12,
    paddingVertical: 7,
    justifyContent: "space-between",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  mainContent: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    flexDirection: "row",
    flex: 1,
  },
});
