import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  SafeAreaView,
  Text,
  TextInput,
} from "../../components";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { useTranslation } from "react-i18next";
import Modal from "react-native-modal";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const SitterWallet = ({ route, navigation }: any) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [bankCode, setBankCode] = useState("");
  const [bankBranchCode, setBankBranchCode] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const cardColor = colors.card;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });
  const onApply = () => {
    setIsModalVisible(false);
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title=""
        subTitle=""
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} />;
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
        renderRight={() => {
          return <Icon name="cog" size={20} />;
        }}
        onPressRight={() => {
          setIsModalVisible(true);
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <View style={{ flex: 1, paddingHorizontal: 20, paddingVertical: 10 }}>
          <Text title2 black>
            {t("sitter_profile:account_balance")}
          </Text>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 25,
            }}
          >
            <Text title2 semibold style={{ fontSize: 24 }}>
              NT$0
            </Text>
          </View>
          <Modal
            isVisible={isModalVisible}
            swipeDirection={["down"]}
            onSwipeComplete={() => {
              setIsModalVisible(false);
            }}
            style={styles.bottomModal}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS === "android" ? "height" : "padding"}
              keyboardVerticalOffset={offsetKeyboard}
            >
              <View
                style={[
                  styles.contentFilterBottom,
                  { backgroundColor: cardColor },
                ]}
              >
                <TouchableWithoutFeedback
                  onPress={() => {
                    Keyboard.dismiss();
                  }}
                >
                  <View style={styles.contentSwipeDown}>
                    <View style={styles.lineSwipeDown} />
                  </View>
                  <View style={{ marginVertical: 10 }}>
                    <Text>{t("sitter_profile:bank_code")}</Text>
                    <TextInput
                      onChangeText={(text: any) => {
                        setBankCode(text);
                      }}
                      onFocus={() => {}}
                      placeholder={t("sitter_profile:bank_code_placeholder")}
                      value={bankCode}
                      style={{ marginTop: 10, borderWidth: 0.5 }}
                      success={true}
                      keyboardType={
                        Platform.OS === "android" ? "numeric" : "number-pad"
                      }
                    />
                  </View>
                  <View style={{ marginVertical: 10 }}>
                    <Text>{t("sitter_profile:bank_branch_code")}</Text>
                    <TextInput
                      onChangeText={(text: any) => {
                        setBankBranchCode(text);
                      }}
                      onFocus={() => {}}
                      placeholder={t(
                        "sitter_profile:bank_branch_code_placeholder",
                      )}
                      value={bankBranchCode}
                      style={{ marginTop: 10, borderWidth: 0.5 }}
                      success={true}
                      keyboardType={
                        Platform.OS === "android" ? "numeric" : "number-pad"
                      }
                    />
                  </View>
                  <View style={{ marginVertical: 10 }}>
                    <Text>{t("sitter_profile:bank_account")}</Text>
                    <TextInput
                      onChangeText={(text: string) => {
                        setBankAccount(text);
                      }}
                      onFocus={() => {}}
                      placeholder={t(
                        "sitter_profile:bank_branch_code_placeholder",
                      )}
                      value={bankAccount}
                      style={{ marginTop: 10, borderWidth: 0.5 }}
                      success={true}
                      keyboardType={
                        Platform.OS === "android" ? "numeric" : "number-pad"
                      }
                    />
                  </View>
                </TouchableWithoutFeedback>
                <Button
                  full
                  style={{ marginTop: 10, marginBottom: 20 }}
                  onPress={() => onApply()}
                >
                  {t("enum:save")}
                </Button>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default SitterWallet;

const styles = StyleSheet.create({
  contentFilterBottom: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 20,
  },
  contentSwipeDown: {
    paddingTop: 10,
    alignItems: "center",
  },
  lineSwipeDown: {
    width: 30,
    height: 2.5,
    backgroundColor: BaseColor.dividerColor,
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
});
