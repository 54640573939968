import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import i18n from "i18next";
import Main from "./Main";
import { useTheme } from "../config";
import PrivacyPolicyScreen from "./PrivacyPolicyScreen";
import * as Linking from "expo-linking";
const RootStack = createStackNavigator();

type MainStackParamList = {
  Main: undefined;
  PrivacyPolicy: undefined
};
const RootNavigation = () => {
  const { theme } = useTheme();
  const language = "ja";

  /**
   * when reducer language change
   */
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const linking: LinkingOptions<MainStackParamList> = {
    prefixes: [Linking.createURL("https://jp.fluv.com")],
    config: {
      initialRouteName: "Main",
      screens: {
        PrivacyPolicy: "privacy-policy",
      },
    },
  };

  return (
    <NavigationContainer theme={theme} linking={linking}>
      <RootStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Main"
      >
        <RootStack.Screen name="Main" component={Main} />
        <RootStack.Screen
          name="PrivacyPolicy"
          component={PrivacyPolicyScreen}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export default RootNavigation;
