import React, { useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  FlatList,
  RefreshControl,
} from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  Text,
  ReserveItem,
} from "../../components";
import { TabView, TabBar } from "react-native-tab-view";
import { useTranslation } from "react-i18next";
import { CurrentReserve } from "../../fakedata";

export default function ReserveScreen({ navigation }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "current", title: t("reserve:current_reserve") },
    { key: "history", title: t("reserve:history_reserve") },
  ]);

  // When tab is activated, set what's index value
  const handleIndexChange = (index: any) => {
    setIndex(index);
  };

  // Customize UI tab bar
  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={[styles.indicator, { backgroundColor: colors.primary }]}
      style={[styles.tabbar, { backgroundColor: colors.background }]}
      tabStyle={styles.tab}
      inactiveColor={BaseColor.grayColor}
      activeColor={colors.text}
      renderLabel={({ route, focused, color }) => (
        <View style={{ flex: 1, alignItems: "center" }}>
          <Text headline semibold={focused} style={{ color }}>
            {route.title}
          </Text>
        </View>
      )}
    />
  );

  // Render correct screen container when tab is activated
  const renderScene = ({ route, jumpTo }: any) => {
    switch (route.key) {
      case "current":
        return <CurrentReserveTab jumpTo={jumpTo} navigation={navigation} />;
      case "history":
        return <HistoryReserveTab jumpTo={jumpTo} navigation={navigation} />;
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header title={t("page:reserve_detail")} subTitle={""} />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <TabView
          lazy
          navigationState={{ index, routes }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={handleIndexChange}
        />
      </SafeAreaView>
    </View>
  );
}

const CurrentReserveTab = (props: any) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const currentReserve = CurrentReserve;
  const [refreshing] = useState(false);

  const renderReserveItem = (item: any) => {
    return (
      <ReserveItem
        name={item.name}
        startDate={item.startDate}
        endDate={item.endDate}
        total={item.total}
        price={item.price}
        style={{ paddingVertical: 10, marginHorizontal: 20 }}
        // onPress={() => {
        //   navigation.navigate('BookingDetail');
        // }}
      />
    );
  };

  return (
    <>
      {currentReserve.length === 0 ? (
        <ScrollView
          contentContainerStyle={{ padding: 20, alignItems: "center" }}
        >
          <Icon
            name="copy"
            size={72}
            color={colors.accentLight}
            style={{ paddingTop: 50, paddingBottom: 20 }}
          />
          <Text title3 style={{ marginVertical: 25 }} semibold>
            {t("reserve:no_current_reserve")}
          </Text>
        </ScrollView>
      ) : (
        <FlatList
          refreshControl={
            <RefreshControl
              colors={[colors.primary]}
              tintColor={colors.primary}
              refreshing={refreshing}
              onRefresh={() => {}}
            />
          }
          data={CurrentReserve}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item }) => renderReserveItem(item)}
        />
      )}
    </>
  );
};

const HistoryReserveTab = (props: any) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const renderReserveItem = (item: any) => {
    return <></>;
  };

  return (
    <ScrollView contentContainerStyle={{ padding: 20, alignItems: "center" }}>
      <Icon
        name="copy"
        size={72}
        color={colors.accentLight}
        style={{ paddingTop: 50, paddingBottom: 20 }}
      />
      <Text title3 style={{ marginVertical: 25 }} semibold>
        {t("reserve:no_history_reserve")}
      </Text>
      {/* <Text body1 grayColor style={{textAlign: 'center'}}>
        {t('payment_completed_text')}
      </Text> */}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  tabbar: {
    height: 40,
  },
  tab: {
    flex: 1,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  containProfileItem: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});
