import React from "react";
import { TextInput, View, StyleProp, ViewStyle } from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";

interface TextInputProps {
  style?: StyleProp<ViewStyle>;
  onChangeText: (text: string) => void;
  onFocus: () => void;
  placeholder?: string;
  value?: any;
  success?: any;
  secureTextEntry?: any;
  keyboardType?: any;
  multiline?: boolean;
  textAlignVertical?: any;
  icon?: any;
  onSubmitEditing?: any;
  numberOfLines?: any;
}

export default (props: TextInputProps) => {
  const { colors } = useTheme();
  const cardColor = colors.card;
  const {
    style,
    onChangeText,
    onFocus,
    placeholder,
    value,
    success,
    secureTextEntry,
    keyboardType,
    multiline,
    textAlignVertical,
    icon,
    onSubmitEditing,
    numberOfLines,
  } = props;
  return (
    <View style={[BaseStyle.textInput, { backgroundColor: cardColor }, style]}>
      <TextInput
        style={{
          flex: 1,
          height: "100%",
          color: colors.text,
          paddingTop: 5,
          paddingBottom: 5,
        }}
        onChangeText={(text) => onChangeText(text)}
        onFocus={() => onFocus()}
        autoCorrect={false}
        placeholder={placeholder}
        placeholderTextColor={success ? BaseColor.grayColor : colors.primary}
        secureTextEntry={secureTextEntry}
        value={value}
        selectionColor={colors.primary}
        keyboardType={keyboardType}
        multiline={multiline}
        numberOfLines={numberOfLines}
        textAlignVertical={textAlignVertical}
        onSubmitEditing={onSubmitEditing}
      />
      {icon}
    </View>
  );
};
