import React from 'react'
import RootNavigation from './src/navigation/RootNavigation'
import './src/config/i18n';

const App = () => {

  return (
    <RootNavigation />
  )
}

export default App