import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { BaseStyle, BaseColor, useTheme } from "../../config";
import { Header, SafeAreaView, Icon, Text, Button } from "../../components";
import { useTranslation } from "react-i18next";

export default function PreviewReserve({ navigation }: any) {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={t("page:preview_reserve")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <ScrollView>
          <View style={{ paddingHorizontal: 20 }}>
            <View
              style={[styles.blockView, { borderBottomColor: colors.border }]}
            >
              <Text body2 style={{ marginBottom: 10 }}>
                {t("enum:type_care_dog")}
              </Text>
              <Text body1 semibold>
                20年経験がある専門家
              </Text>
            </View>
            <View
              style={[styles.blockView, { borderBottomColor: colors.border }]}
            >
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:pets")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    球球
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:start_date")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    5/30
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:end_date")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    5/31
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:times_per_day")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    3回
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={[styles.blockView, { borderBottomColor: colors.border }]}
            >
              <Text body2 style={{ marginBottom: 10 }}>
                {t("preview_reserve:sub_total")}
              </Text>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:dates_number")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    2
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>
                    {t("preview_reserve:service_length_30mins")}
                  </Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    1
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text body2>{t("preview_reserve:times_per_day")}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Text body2 semibold>
                    3
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={[styles.blockView, { borderBottomColor: colors.border }]}
            >
              <Text body2 style={{ marginBottom: 10 }}>
                {t("preview_reserve:notes")}
              </Text>
              <Text body1 semibold style={{ marginBottom: 5 }}>
                なし
              </Text>
            </View>
          </View>
        </ScrollView>
        <View
          style={[
            styles.contentButtonBottom,
            { borderTopColor: colors.border },
          ]}
        >
          <View>
            {/* <Text caption1 semibold grayColor>
              2 {t('day')} / 1 {t('night')}
            </Text> */}
            <Text title3 primaryColor semibold>
              {t("enum:currency")}3500
            </Text>
            {/* <Text caption1 semibold grayColor style={{marginTop: 5}}>
              2 {t('adults')} / 1 {t('children')}
            </Text> */}
          </View>
          <Button onPress={() => navigation.navigate("PaymentMethod")}>
            {t("enum:continue")}
          </Button>
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blockView: {
    paddingVertical: 10,
    borderBottomWidth: 1,
  },
});
