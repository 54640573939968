import { StyleSheet, Text, View } from "react-native";
import React from "react";

const SitterProfileEdit = () => {
  return (
    <View style={{ flex: 1 }}>
      <Text>SitterProfileEdit</Text>
    </View>
  );
};

export default SitterProfileEdit;

const styles = StyleSheet.create({});
