import React, { useState } from "react";
import {
  View,
  Animated,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { useTranslation } from "react-i18next";
import {
  SafeAreaView,
  Text,
  Card,
  Button,
  Icon,
  StarRating,
} from "../../components";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Images } from "../../config/images";
import * as Utils from "../../utils";
import { Icons, TopSitter, AreaSitter } from "../../fakedata";

const HomeScreen = ({ navigation }: any) => {
  const [heightHeader, setHeightHeader] = useState(Utils.heightHeader());
  const { t } = useTranslation();
  const deltaY = new Animated.Value(0);
  const heightImageBanner = Utils.scaleWithPixel(150);
  const marginTopBanner = heightImageBanner - heightHeader;
  const { colors } = useTheme();
  const [icons] = useState(Icons);
  const [topSitter] = useState(TopSitter);
  /**
   * @description Show icon services on form searching
   * @author Passion UI <passionui.com>
   * @date 2019-08-03
   * @returns
   */
  const renderIconService = () => {
    return (
      <FlatList
        data={icons}
        numColumns={4}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={styles.itemService}
              activeOpacity={0.9}
              onPress={() => {
                navigation.navigate(item.route, {
                  type: item.type,
                });
              }}
            >
              <View
                style={[styles.iconContent, { backgroundColor: colors.card }]}
              >
                <Icon
                  name={t(item.icon)}
                  color={colors.primary}
                  size={18}
                  solid
                />
              </View>
              <Text footnote grayColor numberOfLines={1}>
                {t(`search:${item.name}`)}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Animated.Image
        source={Images.banners.banner1}
        style={[
          styles.imageBackground,
          {
            height: deltaY.interpolate({
              inputRange: [
                0,
                Utils.scaleWithPixel(100),
                Utils.scaleWithPixel(100),
              ],
              outputRange: [heightImageBanner, heightHeader, 0],
            }),
          },
        ]}
      />
      <SafeAreaView style={BaseStyle.safeAreaView} edges={["right", "left"]}>
        <FlatList
          data={[]}
          renderItem={() => <></>}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: { y: deltaY },
                },
              },
            ],
            {
              useNativeDriver: false,
            },
          )}
          onContentSizeChange={() => setHeightHeader(Utils.heightHeader())}
          scrollEventThrottle={8}
          ListHeaderComponent={
            <View style={{ paddingHorizontal: 20 }}>
              <View
                style={[
                  styles.searchForm,
                  {
                    marginTop: marginTopBanner,
                    backgroundColor: colors.background,
                    borderColor: colors.border,
                    shadowColor: colors.border,
                  },
                ]}
              >
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("Search", {
                      type: "care_dog",
                    })
                  }
                  activeOpacity={0.9}
                >
                  <View
                    style={[
                      BaseStyle.textInput,
                      { backgroundColor: colors.card },
                    ]}
                  >
                    <Text body1 grayColor style={{ fontSize: 14 }}>
                      {t("search:search_nearby")}
                    </Text>
                  </View>
                </TouchableOpacity>
                {renderIconService()}
              </View>
            </View>
          }
          ListFooterComponent={
            <View>
              <View>
                <Text title3 semibold style={styles.titleView}>
                  {t("enum:top_sitter")}
                </Text>
                <FlatList
                  contentContainerStyle={{ paddingLeft: 5, paddingRight: 20 }}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  data={topSitter}
                  keyExtractor={(item, index) => item.id}
                  renderItem={({ item, index }) => (
                    <Card
                      style={[styles.promotionItem, { marginLeft: 15 }]}
                      image={item.image}
                      onPress={() =>
                        navigation.navigate("SitterDetail", {
                          sitterDetail: item,
                        })
                      }
                    >
                      <View
                        style={{
                          backgroundColor: "black",
                          opacity: 0.5,
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        <Text
                          title2
                          whiteColor
                          semibold
                          style={{ paddingBottom: 5 }}
                        >
                          {item.name}
                        </Text>
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <StarRating
                            disabled={true}
                            starSize={10}
                            maxStars={5}
                            rating={5}
                            selectedStar={(rating) => {}}
                            fullStarColor={BaseColor.yellowColor}
                          />
                          <Text
                            caption1
                            semibold
                            whiteColor
                            style={{
                              marginLeft: 10,
                              marginRight: 3,
                            }}
                          >
                            {t("enum:rating")}
                          </Text>
                          <Text caption1 whiteColor semibold>
                            （85）
                          </Text>
                        </View>
                      </View>
                      <View style={styles.contentCartPromotion}>
                        <Button
                          style={[styles.btnPromotion, { borderRadius: 5 }]}
                          onPress={() => {
                            navigation.navigate("SitterDetail");
                          }}
                        >
                          <Text body2 semibold whiteColor>
                            {t("enum:reserve_now")}
                          </Text>
                        </Button>
                      </View>
                    </Card>
                  )}
                />
              </View>

              <View style={styles.titleView}>
                <Text title3 semibold>
                  {/* 地區：東京都 */}
                  {t("enum:area")}
                </Text>
                <Text body2 grayColor>
                  {/* 目前離你最近的保姆 */}
                  {t("search:search_nearby")}
                </Text>
              </View>
              <FlatList
                contentContainerStyle={{ paddingLeft: 5, paddingRight: 20 }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={AreaSitter}
                keyExtractor={(item, index) => item.id}
                renderItem={({ item, index }) => (
                  <Card
                    style={[styles.tourItem, { marginLeft: 15 }]}
                    image={item.image}
                    onPress={() =>
                      navigation.navigate("SitterDetail", {
                        sitterDetail: item,
                      })
                    }
                  >
                    <View
                      style={{
                        backgroundColor: "black",
                        opacity: 0.5,
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        title2
                        whiteColor
                        semibold
                        style={{ paddingBottom: 5 }}
                      >
                        {item.name}
                      </Text>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <StarRating
                          disabled={true}
                          starSize={10}
                          maxStars={5}
                          rating={5}
                          selectedStar={(rating) => {}}
                          fullStarColor={BaseColor.yellowColor}
                        />
                        <Text
                          caption1
                          semibold
                          whiteColor
                          style={{
                            marginLeft: 10,
                            marginRight: 3,
                          }}
                        >
                          {t("enum:rating")}
                        </Text>
                        <Text caption1 whiteColor semibold>
                          85
                        </Text>
                      </View>
                    </View>
                  </Card>
                )}
              />
            </View>
          }
        />
      </SafeAreaView>
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  imageBackground: {
    height: 150,
    width: "100%",
    position: "absolute",
  },
  searchForm: {
    padding: 10,
    borderRadius: 10,
    borderWidth: 0.5,
    width: "100%",
    shadowColor: "black",
    shadowOffset: { width: 1.5, height: 1.5 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
    elevation: 1,
  },
  contentServiceIcon: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  contentCartPromotion: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  btnPromotion: {
    height: 25,
    borderRadius: 3,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  contentHiking: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 10,
  },
  promotionBanner: {
    height: Utils.scaleWithPixel(100),
    width: "100%",
    marginTop: 10,
  },
  line: {
    height: 1,
    marginTop: 10,
    marginBottom: 15,
  },
  iconContent: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  itemService: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingTop: 10,
  },
  promotionItem: {
    width: Utils.scaleWithPixel(200),
    height: Utils.scaleWithPixel(250),
  },
  tourItem: {
    width: Utils.scaleWithPixel(135),
    height: Utils.scaleWithPixel(160),
  },
  titleView: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
});
