import { StyleSheet, TouchableOpacity, View } from "react-native";
import { BaseColor, useTheme } from "../config";
import { Text, StarRating, Tag, ProfileDetail, Image } from "../components";
import * as Utils from "../utils";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5";

interface SearchItemProps {
  list?: any;
  block?: any;
  grid?: any;
  style?: any;
  image?: any;
  name?: any;
  price?: any;
  rate?: any;
  rateCount?: any;
  author?: any;
  onPress?: any;
  onPressUser?: any;
  onPressBookNow?: any;
  location?: any;
  startTime?: any;
  tags?: any;
  travelTime?: any;
  numReviews?: any;
}

const SearchItem = (props: SearchItemProps) => {
  const { t } = useTranslation();
  const {
    list,
    block,
    grid,
    style,
    image,
    name,
    price,
    rate,
    rateCount,
    author,
    onPress,
    onPressUser,
    onPressBookNow,
    location,
    startTime,
    tags,
    travelTime,
    numReviews,
  } = props;
  const { colors } = useTheme();
  /**
   * Display Search item as block
   */
  const renderBlock = () => {
    return (
      <View style={style}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.9}>
          <Image source={image} style={styles.blockImage} />
          <View
            style={[
              styles.blockPriceContent,
              { backgroundColor: colors.primary },
            ]}
          >
            <Text title3 whiteColor semibold>
              {t("enum:currency")} {price}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{ marginTop: 5, paddingHorizontal: 20 }}>
          <ProfileDetail
            image={author.image}
            textFirst={name}
            textSecond={author.name}
            point={author.point}
            icon={false}
            style={{ marginTop: 10 }}
            onPress={onPressUser}
          />
          <View style={styles.blockDetailContent}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <StarRating
                disabled={true}
                starSize={10}
                maxStars={5}
                rating={rate}
                selectedStar={(rating: any) => {}}
                fullStarColor={BaseColor.yellowColor}
              />
              <Text caption1 primaryColor semibold>
                {rateCount}
              </Text>
            </View>
            <Tag outline round style={{ height: 30 }} onPress={onPressBookNow}>
              {/* {t('book_now')} */}
              クイックオーダー
            </Tag>
          </View>
        </View>
      </View>
    );
  };

  /**
   * Display Tour item as grid
   */
  const renderGrid = () => {
    return (
      <View style={[styles.girdContent, style]}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.9}>
          <Image source={image} style={styles.girdImage} />
        </TouchableOpacity>
        <View style={styles.girdContentLocation}>
          <Icon name="map-marker-alt" color={colors.primary} size={10} />
          <Text
            caption2
            grayColor
            style={{
              marginLeft: 3,
            }}
          >
            {location}
          </Text>
        </View>
        <Text
          body2
          semibold
          style={{
            marginTop: 5,
          }}
          numberOfLines={1}
        >
          {name}
        </Text>
        <View style={styles.listContentRate}>
          <StarRating
            disabled={true}
            starSize={10}
            maxStars={5}
            rating={rate}
            selectedStar={(rating) => {}}
            fullStarColor={BaseColor.yellowColor}
          />
          <Text
            caption1
            grayColor
            semibold
            style={{
              marginLeft: 10,
              marginRight: 3,
            }}
          >
            {t("enum:rating")}
          </Text>
          <Text caption1 primaryColor semibold>
            {rateCount}
          </Text>
        </View>
        <Text
          title3
          primaryColor
          semibold
          style={{
            marginTop: 5,
          }}
        >
          {t("enum:currency")} {price}
        </Text>
      </View>
    );
  };

  /**
   * Display Tour item as list
   */
  const renderList = () => {
    return (
      <View style={[styles.listContent, style]}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.9}>
          <Image source={image} style={styles.listImage} />
        </TouchableOpacity>
        <View style={styles.listContentRight}>
          <Text headline semibold>
            {name}
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginTop: 5,
            }}
          >
            <View style={styles.listContentIcon}>
              <Icon
                name="calendar-alt"
                color={BaseColor.grayColor}
                size={10}
                solid
              />
              <Text
                caption1
                grayColor
                style={{
                  marginLeft: 3,
                }}
              >
                {startTime}
              </Text>
            </View>
            <View style={styles.listContentIcon}>
              <Icon
                name="map-marker-alt"
                color={BaseColor.grayColor}
                size={10}
              />
              <Text
                caption1
                grayColor
                style={{
                  marginLeft: 3,
                }}
              >
                {location}
              </Text>
            </View>
          </View>
          <View style={styles.listContentRate}>
            <StarRating
              disabled={true}
              starSize={10}
              maxStars={5}
              rating={rate}
              selectedStar={(rating) => {}}
              fullStarColor={BaseColor.yellowColor}
            />
            <Text
              caption1
              grayColor
              semibold
              style={{
                marginLeft: 10,
                marginRight: 3,
              }}
            >
              {t("eneum:rating")}
            </Text>
            <Text caption1 primaryColor semibold>
              {rateCount}
            </Text>
          </View>
          <View style={styles.listContentService}>
            {tags.map((item: any, index: any) => {
              return (
                <Tag
                  key={"service" + index}
                  icon={
                    <Icon
                      name={item.icon}
                      size={12}
                      color={colors.accent}
                      solid
                      style={{ marginRight: 5 }}
                    />
                  }
                  chip
                  style={{
                    marginTop: 5,
                    marginRight: 5,
                  }}
                >
                  {t(`license:${item.name}`)}
                </Tag>
              );
            })}
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
              marginTop: 5,
              alignItems: "flex-end",
            }}
          >
            <Text title3 primaryColor semibold>
              {t("enum:currency")} {price}
            </Text>
          </View>
        </View>
      </View>
    );
  };
  if (grid) return renderGrid();
  else if (block) return renderBlock();
  else return renderList();
};

export default SearchItem;

const styles = StyleSheet.create({
  //css Gird
  girdContent: {
    flexDirection: "column",
    flex: 1,
  },
  girdContentLocation: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
  },
  girdContentRate: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },

  girdImage: {
    borderRadius: 8,
    height: Utils.scaleWithPixel(120),
    width: "100%",
  },
  //css List
  listContentService: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tag: {
    flexDirection: "row",
    padding: 5,
    marginTop: 5,
    marginRight: 5,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 10,
  },
  listContentRate: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  listContentIcon: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  listContentRight: {
    marginHorizontal: 10,
    flex: 1,
  },
  listContent: {
    flexDirection: "row",
  },
  listImage: {
    height: Utils.scaleWithPixel(150, 1),
    width: Utils.scaleWithPixel(120, 1),
    borderRadius: 8,
  },
  //css block
  blockPriceContent: {
    position: "absolute",
    top: 10,
    left: 20,
    borderRadius: 8,
    padding: 5,
  },
  blockDetailContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blockImage: {
    height: Utils.scaleWithPixel(200),
    width: "100%",
  },
});
