import { Images } from "../config/images";

export default [
  {
    id: "1",
    image: Images.topsitter.sitter1,
    name: "千夏",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2022",
    price: "3500",
    rate: 5,
    rateCount: "（85）",
    numReviews: 100,
    author: {
      image: Images.services.service1,
      point: "9.5",
      name: "",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
  {
    id: "2",
    image: Images.topsitter.sitter2,
    name: "梨々香",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2022",
    price: "3000",
    rate: 5,
    rateCount: "（85）",
    numReviews: 100,
    author: {
      image: Images.services.service2,
      point: "9.5",
      name: "",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
    ],
  },
  {
    id: "3",
    image: Images.topsitter.sitter3,
    name: "優樹菜",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "May 30th 2022",
    price: "4000",
    rate: 5,
    rateCount: "（85）",
    numReviews: 100,
    author: {
      image: Images.services.service3,
      point: "9.5",
      name: "",
    },
    tags: [
      { icon: "id-card", name: "animal_assisted_welfare_worker" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
  {
    id: "4",
    image: Images.topsitter.sitter4,
    name: "日菜子",
    location: "東京都新宿区",
    travelTime: "8 Days 7 Nights",
    startTime: "July 19th 2022",
    price: "3000",
    rate: 5,
    rateCount: "（85）",
    numReviews: 100,
    author: {
      image: Images.services.service4,
      point: "9.5",
      name: "",
    },
    tags: [
      { icon: "certificate", name: "domestic_dog_trainer" },
      { icon: "id-card", name: "trimmer" },
    ],
  },
];
