import React from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
  ImageStyle,
} from "react-native";
import { Image, Text } from "../../components";
import { BaseColor, useTheme } from "../../config";
interface ListThumbCircleProps {
  style?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  image: string;
  txtLeftTitle: string;
  txtContent: string;
  txtRight: string;
  txtRightNumber: string;
  onPress: () => void;
}
export default function ListThumbCircle(props: ListThumbCircleProps) {
  const { colors } = useTheme();
  const {
    style,
    imageStyle,
    image,
    txtLeftTitle,
    txtContent,
    txtRight,
    txtRightNumber,
    onPress,
  } = props;
  return (
    <TouchableOpacity
      style={[
        styles.contain,
        { borderBottomWidth: 1, borderBottomColor: colors.border },
        style,
      ]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <Image source={image} style={[styles.thumb, imageStyle]} />
      <View style={styles.content}>
        <View style={styles.left}>
          <Text headline semibold>
            {txtLeftTitle}
          </Text>
          <Text
            numberOfLines={1}
            footnote
            grayColor
            style={{
              paddingTop: 5,
            }}
          >
            {txtContent}
          </Text>
        </View>
        <View style={styles.right}>
          <Text caption2 grayColor numberOfLines={1}>
            {txtRight}
          </Text>
          <View style={styles.numberContainer}>
            <Text style={styles.number}>{txtRightNumber}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
  },
  thumb: { width: 48, height: 48, marginRight: 10, borderRadius: 24 },
  content: {
    flex: 1,
    flexDirection: "row",
  },
  left: {
    flex: 7.5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  right: {
    flex: 2.5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  numberContainer: {},
  number: {
    textAlign: "center",
    marginTop: 5,
    backgroundColor: BaseColor.dangerColor,
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 10,
    overflow: "hidden",
    color: BaseColor.whiteColor,
  },
});
