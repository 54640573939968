export default [
  {
    id: "1",
    name: "care_dog",
    startDate: "05/06",
    endDate: "05/07",
    checkIn: "05/06",
    checkOut: "07 Jun 08",
    total: "",
    price: "¥1,916",
  },
  {
    id: "2",
    name: "care_dog",
    startDate: "05/06",
    endDate: "05/07",
    checkIn: "04 Jun 08",
    checkOut: "07 Jun 08",
    total: "",
    serviceType: "care_dog",
    price: "¥1,916",
  },
  {
    id: "3",
    name: "care_dog",
    startDate: "05/06",
    endDate: "05/07",
    checkIn: "04 Jun 08",
    checkOut: "07 Jun 08",
    total: "",
    serviceType: "care_baby_dog",
    price: "¥1,916",
  },
  {
    id: "4",
    name: "care_baby_cat",
    startDate: "05/06",
    endDate: "05/07",
    checkIn: "04 Jun 08",
    checkOut: "07 Jun 08",
    total: "",
    serviceType: "care_baby_cat",
    price: "¥1,916",
  },
];
