import React, { useState } from "react";
import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import { BaseStyle, useTheme } from "../../config";
import {
  Header,
  SafeAreaView,
  Icon,
  Text,
  Button,
  ProfileDetail,
  ProfilePerformance,
} from "../../components";
import { UserData } from "../../fakedata";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";

export default function Profile({ navigation }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [userData] = useState(UserData[0]);

  const onLogOut = () => {
    setLoading(false);
    navigation.navigate("SitterProfile");
  };

  return (
    <View style={{ flex: 1 }}>
      <Header title={t("tabs:profile")} subTitle={""} />
      <ProfileDetail
        style={{ padding: 20 }}
        image={userData.image}
        textFirst={userData.name}
        textSecond={userData.address}
        icon={true}
        onPress={() => navigation.navigate("ProfileEdit")}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <ScrollView>
          <View style={styles.contain}>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("SitterProfile");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="id-card" size={18} />
                <Text body1>{t("profile:sitter_profile")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("Pets");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="paw" size={18} />
                <Text body1>{t("profile:pets")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("SitterProfile");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="gift" size={18} />
                <Text body1>{t("profile:coupon")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("SitterProfile");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="gifts" size={18} />
                <Text body1>{t("profile:invite_friends")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.profileItem,
                {
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  marginTop: 10,
                },
              ]}
              onPress={() => {
                navigation.navigate("SitterProfile");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="headset" size={18} />
                <Text body1>{t("profile:support")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.profileItem}
              onPress={() => {
                navigation.navigate("Setting");
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Icon style={{ marginRight: 10 }} name="cog" size={18} />
                <Text body1>{t("profile:setting")}</Text>
              </View>
              <Icon
                name="angle-right"
                size={18}
                color={colors.primary}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
            <View style={{ paddingHorizontal: 20, paddingVertical: 15 }}>
              <Button
                style={{ backgroundColor: colors.background }}
                styleText={{ color: BaseColor.dangerColor }}
                full
                loading={loading}
                onPress={() => onLogOut()}
              >
                {t("enum:sign_out")}
              </Button>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentTitle: {
    alignItems: "flex-start",
    width: "100%",
    height: 32,
    justifyContent: "center",
  },
  contain: {
    flex: 1,
    paddingHorizontal: 20,
  },
  textInput: {
    height: 56,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
  },
  profileItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
  },
});
