import React, { useState } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import { BaseStyle, useTheme } from "../../config";
import {
  Image,
  Header,
  SafeAreaView,
  Icon,
  Text,
  Button,
  TextInput,
  SelectModal,
} from "../../components";
import { UserData } from "../../fakedata";
import { useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function PetProfileEdit({ route, navigation }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { petProfile } = route.params;
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });

  const categoryOptions = [
    {
      value: "dog",
      text: "pets:category_DOG",
    },
    {
      value: "cat",
      text: "pets:category_CAT",
    },
  ];

  const [id, setId] = useState(UserData[0].id);
  const [name, setName] = useState(UserData[0].name);
  const [category, setCategory] = useState("犬");
  const [size, setSize] = useState("小型");
  const [birthYear, setBirthYear] = useState(2014);
  const [image] = useState(UserData[0].image);
  const [loading, setLoading] = useState(false);

  const [onOpenCategoryModal, setOnOpenCategoryModal] = useState(false);

  const onCategorySelect = (category: any) => {
    console.log(category);
    setOnOpenCategoryModal(false);
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={petProfile.name ? t("pets:update_pet") : t("pets:add_pet")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
        onPressRight={() => {}}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "android" ? "height" : "padding"}
          keyboardVerticalOffset={offsetKeyboard}
          style={{ flex: 1 }}
        >
          <ScrollView contentContainerStyle={styles.contain}>
            <View>
              <Image
                source={{ uri: petProfile.avatarId }}
                style={styles.thumb}
              />
            </View>

            <View style={styles.contentTitle}>
              <Text headline semibold>
                {t("pets:name")}
              </Text>
            </View>
            <TextInput
              onChangeText={(text) => setId(text)}
              onFocus={() => {}}
              placeholder={t("pets:name_placeholder")}
              value={petProfile.name}
            />

            <View style={[styles.contentTitle]}>
              <Text headline semibold>
                {t("pets:health_status")}
              </Text>
            </View>
            <TextInput
              style={{ height: "auto" }}
              numberOfLines={4}
              multiline={true}
              onChangeText={(text) => setName(text)}
              onFocus={() => {}}
              placeholder={t("pets:health_status_placeholder")}
              value={petProfile.healthStatus}
            />

            <View style={styles.contentTitle}>
              <Text headline semibold>
                {t("pets:category")}
              </Text>
            </View>
            <View style={styles.selectContent}>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenCategoryModal(true);
                }}
              >
                <Text>{category}</Text>
                <Icon name="angle-down" size={18} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenCategoryModal}
              options={categoryOptions}
              selected={{ value: "dog", text: "狗" }}
              onChange={onCategorySelect}
            />

            <View style={styles.contentTitle}>
              <Text headline semibold>
                {t("pets:size")}
              </Text>
            </View>
            <View style={styles.selectContent}>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenCategoryModal(true);
                }}
              >
                <Text>{size}</Text>
                <Icon name="angle-down" size={18} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenCategoryModal}
              options={categoryOptions}
              selected={{ value: "dog", text: "狗" }}
              onChange={onCategorySelect}
            />

            <View style={styles.contentTitle}>
              <Text headline semibold>
                {t("pets:birth_year")}
              </Text>
            </View>
            <View style={styles.selectContent}>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenCategoryModal(true);
                }}
              >
                <Text>{birthYear}</Text>
                <Icon name="angle-down" size={18} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenCategoryModal}
              options={categoryOptions}
              selected={{ value: "dog", text: "狗" }}
              onChange={onCategorySelect}
            />

            <View style={styles.contentTitle}>
              <Text headline semibold>
                {t("pets:birth_month")}
              </Text>
            </View>
            <View style={styles.selectContent}>
              <TouchableOpacity
                style={styles.selectButton}
                onPress={() => {
                  setOnOpenCategoryModal(true);
                }}
              >
                <Text>{birthYear}</Text>
                <Icon name="angle-down" size={18} />
              </TouchableOpacity>
            </View>
            <SelectModal
              visible={onOpenCategoryModal}
              options={categoryOptions}
              selected={{ value: "dog", text: "狗" }}
              onChange={onCategorySelect}
            />
          </ScrollView>
          <View style={{ paddingVertical: 15, paddingHorizontal: 20 }}>
            <Button
              loading={loading}
              full
              onPress={() => {
                setLoading(true);
                setTimeout(() => {
                  navigation.goBack();
                }, 500);
              }}
            >
              {t("enum:save")}
            </Button>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentTitle: {
    alignItems: "flex-start",
    width: "100%",
    height: 32,
    justifyContent: "center",
    marginTop: 10,
  },
  contain: {
    alignItems: "center",
    padding: 20,
  },
  textInput: {
    height: 46,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    color: BaseColor.grayColor,
  },
  thumb: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 20,
  },
  selectContent: {
    width: "100%",
    flex: 1,
  },
  selectButton: {
    padding: 10,
    height: 46,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: BaseColor.grayColor,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    flexDirection: "row",
  },
});
