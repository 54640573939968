import React, { useState } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { Header, SafeAreaView, Icon } from "../../components";
import { useTranslation } from "react-i18next";
import { PetsData } from "../../fakedata";
import PetItem from "../../components/PetItem";

export default function Pets({ navigation }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [refreshing] = useState(false);

  const renderReserveItem = (item: any) => {
    return (
      <PetItem
        name={item.name}
        avatarId={item.avatarId}
        healthStatus={item.healthStatus}
        category={item.category}
        style={{ paddingVertical: 10, marginHorizontal: 20 }}
        onPress={() => {
          navigation.navigate("PetProfileEdit", {
            petProfile: item,
          });
        }}
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={t("pets:title")}
        subTitle={""}
        renderLeft={() => (
          <Icon name="arrow-left" size={20} color={colors.primary} />
        )}
        onPressLeft={() => {
          navigation.goBack();
        }}
        renderRight={() => (
          <Icon name="plus" size={20} color={colors.primary} />
        )}
        onPressRight={() => {
          navigation.navigate("PetProfileEdit", {
            petProfile: {},
          });
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <FlatList
          refreshControl={
            <RefreshControl
              colors={[colors.primary]}
              tintColor={colors.primary}
              refreshing={refreshing}
              onRefresh={() => {}}
            />
          }
          data={PetsData}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item }) => renderReserveItem(item)}
        />
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  tabbar: {
    height: 40,
  },
  tab: {
    flex: 1,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  containProfileItem: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});
