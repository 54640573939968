// import {useSelector} from 'react-redux';
import { Theme } from "@react-navigation/native";
import { useColorScheme } from "react-native";
/**
 * Define Const color use for whole application
 */
export const BaseColor = {
  grayColor: "#9B9B9B",
  dividerColor: "#BDBDBD",
  whiteColor: "#FFFFFF",
  fieldColor: "#F5F5F5",
  yellowColor: "#FDC60A",
  navyBlue: "#3C5A99",
  kashmir: "#5D6D7E",
  orangeColor: "#E5634D",
  blueColor: "#5DADE2",
  pinkColor: "#A569BD",
  greenColor: "#58D68D",
  successColor: "#05bb85",
  warningColor: "#d90",
  dangerColor: "#f77",
};

/**
 * Define default theme use for whole application
 */
export const DefaultTheme = {
  theme: "#79c7ce",
  light: {
    dark: false,
    colors: {
      primary: "#79c7ce",
      primaryDark: "#59a7ae",
      primaryLight: "#8bdadf",
      accent: "#ff7e8e",
      accentLight: "#ff9aa6",
      background: "white",
      card: "#F5F5F5",
      text: "#212121",
      border: "#c7c7cc",
      notification: "",
    },
  },
  dark: {
    dark: true,
    colors: {
      primary: "#79c7ce",
      primaryDark: "#59a7ae",
      primaryLight: "#8bdadf",
      accent: "#ff7e8e",
      accentLight: "#ff9aa6",
      background: "white",
      card: "#F5F5F5",
      text: "#212121",
      border: "#c7c7cc",
      notification: "",
    },
  },
};

/**
 * Define font default use for whole application
 */
export const DefaultFont = "Roboto";

export const useTheme = () => {
  const isDarkMode = useColorScheme() === "dark";
  interface DefaultTheme {
    theme: Theme;
    colors: any;
  }
  if (isDarkMode) {
    return {
      theme: DefaultTheme.dark,
      colors: DefaultTheme.dark.colors,
    } as DefaultTheme;
  }
  return {
    theme: DefaultTheme.light,
    colors: DefaultTheme.light.colors,
  } as DefaultTheme;
};
