import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Image, Text } from "../components";
import { BaseColor, useTheme } from "../config";
import Icon from "react-native-vector-icons/FontAwesome5";

export default function ProfileDetail(props: any) {
  const { colors } = useTheme();
  const {
    style,
    image,
    styleLeft,
    styleThumb,
    styleRight,
    onPress,
    textFirst,
    point,
    textSecond,
    textThird,
    icon,
  } = props;
  return (
    <TouchableOpacity
      style={[styles.contain, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <View style={[styles.contentLeft, styleLeft]}>
        <View>
          <Image source={image} style={[styles.thumb, styleThumb]} />
          {point ? (
            <View
              style={[styles.point, { backgroundColor: colors.primaryLight }]}
            >
              <Text overline whiteColor semibold>
                {point}
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
        <View style={{ alignItems: "flex-start" }}>
          <Text headline semibold numberOfLines={1}>
            {textFirst}
          </Text>
          <Text
            body2
            style={{
              marginTop: 3,
              paddingRight: 10,
            }}
            numberOfLines={1}
          >
            {textSecond}
          </Text>
          <Text footnote grayColor numberOfLines={1}>
            {textThird}
          </Text>
        </View>
      </View>
      {icon && (
        <View style={[styles.contentRight, styleRight]}>
          <Icon name="angle-right" size={18} color={BaseColor.grayColor} />
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: { flexDirection: "row" },
  contentLeft: {
    flex: 8,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  thumb: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 10,
  },
  contentRight: {
    flex: 2,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  point: {
    width: 18,
    height: 18,
    borderRadius: 9,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 9,
    bottom: 0,
  },
});
