import React, { useState, useRef } from "react";
import {
  View,
  FlatList,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import { Images, useTheme } from "../../config";
import { Header, Icon, Image, Text, TextInput } from "../../components";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../utils";

const MessengerScreen = ({ navigation }: any) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const offsetKeyboard = Platform.select({
    ios: 0,
    android: 20,
  });
  const refFlatList = useRef<FlatList>(null);
  const [input, setInput] = useState("");
  const [isScrollEnd, setIsScrollEnd] = useState(true);
  const [isFocusTextInput, setIsFocusTextInput] = useState(false);
  const [messages, setMessages] = useState([
    {
      id: 1,
      message: "そろそろナナと戻りますよ！",
      image: "",
      created: "08:45 AM",
      user: {
        id: 1,
        name: "佐藤香織",
        avatar: Images.topsitter.sitter3,
      },
    },
    {
      id: 2,
      message: "そろそろナナと戻りますよ！",
      image: "https://i.imgur.com/oc0kN1j.jpg",
      created: "08:45 AM",
      user: {
        id: 1,
        name: "佐藤香織",
        avatar: Images.topsitter.sitter3,
      },
    },
    {
      id: 3,
      message: "そろそろナナと戻りますよ！",
      image: "https://i.imgur.com/pBe0v4N.jpg",
      created: "08:45 AM",
      user: {
        id: 1,
        name: "佐藤香織",
        avatar: Images.topsitter.sitter3,
      },
    },
    {
      id: 4,
      message: "お疲れ様です！はい〜ナナは楽しいそうですね🥰",
      image: "",
      created: "08:43 AM",
    },
  ]);

  const renderItem = (item: any) => {
    if (item.user) {
      return (
        <View
          style={[
            styles.userContent,
            item.image === "" ? {} : styles.userImageContent,
            { marginBottom: 10 },
          ]}
        >
          <Image
            source={Images.topsitter.sitter3}
            style={[styles.avatar, { borderColor: colors.accent }]}
          />
          <View style={{ paddingHorizontal: 8, flex: 7 }}>
            <Text caption1>{item.user.name}</Text>
            {item.image === "" ? (
              <View
                style={[
                  styles.userContentMessage,
                  { backgroundColor: colors.primary },
                ]}
              >
                <Text body2 whiteColor>
                  {item.message}
                </Text>
              </View>
            ) : (
              <Image
                source={{ uri: item.image }}
                style={{ width: "100%", borderRadius: 20, marginTop: 10 }}
              />
            )}
          </View>
          <View style={styles.userContentDate}>
            <Text footnote numberOfLines={1}>
              {item.created}
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View
        style={[
          styles.meContent,
          item.image === "" ? {} : styles.meImageContent,
        ]}
      >
        <View style={styles.meContentDate}>
          <Text footnote numberOfLines={1}>
            {item.created}
          </Text>
        </View>
        <View style={{ paddingLeft: 8, flex: 7 }}>
          {item.image === "" ? (
            <View
              style={[
                styles.meContentMessage,
                { backgroundColor: colors.card },
              ]}
            >
              <Text body2>{item.message}</Text>
            </View>
          ) : (
            <Image
              source={{ uri: item.image }}
              style={{ width: "100%", borderRadius: 20 }}
            />
          )}
        </View>
      </View>
    );
  };

  const sendMessage = () => {
    if (input != "") {
      messages.push({
        id: Math.random(),
        message: input,
        image: "",
        created: "08:45 AM",
      });
      setInput("");
      setMessages(messages);
      if (refFlatList.current) {
        setTimeout(() => {
          refFlatList.current?.scrollToEnd({ animated: false });
        }, 500);
      }
    }
  };

  const scrollToEnd = () => {
    refFlatList.current?.scrollToEnd();
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={"佐藤香織"}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
      />
      <KeyboardAvoidingView
        style={{ flex: 1, justifyContent: "flex-end" }}
        behavior={Platform.OS === "android" ? "height" : "padding"}
        keyboardVerticalOffset={offsetKeyboard}
        enabled
      >
        <View style={{ flex: 1 }}>
          <FlatList
            ref={refFlatList}
            data={messages}
            keyExtractor={(item, index) => item.id.toString()}
            renderItem={({ item }) => renderItem(item)}
            onScroll={(e) => {
              const sHeight = e.nativeEvent.contentSize.height;
              const lHeight = e.nativeEvent.layoutMeasurement.height;
              const cHeight = e.nativeEvent.contentOffset.y;
              if (sHeight - lHeight === cHeight) {
                setIsScrollEnd(true);
              } else {
                setIsScrollEnd(false);
              }
            }}
            onLayout={() => {
              scrollToEnd();
            }}
          />
          <View style={styles.inputContent}>
            {isFocusTextInput ? (
              <>
                <TouchableOpacity
                  style={[styles.sendIcon]}
                  onPress={() => {
                    setIsFocusTextInput(false);
                  }}
                >
                  <Icon name="angle-right" size={24} color={colors.primary} />
                </TouchableOpacity>
              </>
            ) : (
              <>
                <TouchableOpacity
                  style={[styles.sendIcon]}
                  onPress={uploadImage}
                >
                  <Icon name="plus" size={20} color={colors.primary} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.sendIcon]}
                  onPress={uploadImage}
                >
                  <Icon name="image" size={20} color={colors.primary} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.sendIcon, { marginRight: 8 }]}
                  onPress={uploadImage}
                >
                  <Icon name="camera" size={20} color={colors.primary} />
                </TouchableOpacity>
              </>
            )}

            <View style={{ flex: 1 }}>
              <TextInput
                style={{ borderRadius: 20 }}
                onChangeText={(text) => {
                  setInput(text);
                  setIsFocusTextInput(true);
                }}
                onFocus={() => {
                  setIsFocusTextInput(true);
                }}
                onSubmitEditing={() => sendMessage()}
                placeholder={t("messenger:type_message")}
                value={input}
              />
            </View>
            <TouchableOpacity style={[styles.sendIcon]} onPress={sendMessage}>
              <Icon name="paper-plane" size={20} color={colors.primary} />
            </TouchableOpacity>
            {isScrollEnd ? (
              <></>
            ) : (
              <TouchableOpacity
                style={[
                  {
                    position: "absolute",
                    top: -30,
                    right: 15,
                    backgroundColor: colors.primary,
                    padding: 5,
                    borderRadius: 5,
                  },
                ]}
                onPress={scrollToEnd}
              >
                <Icon name="arrow-down" size={16} color={"white"} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default MessengerScreen;

const styles = StyleSheet.create({
  inputContent: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 20,
    alignItems: "center",
    flexDirection: "row",
  },
  sendIcon: {
    width: 30,
    height: 30,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
  },
  userContent: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
  },
  userImageContent: {
    maxHeight: 200,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    marginTop: 24,
  },
  userContentMessage: {
    marginTop: 8,
    padding: 16,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    flex: 1,
  },
  userContentDate: {
    flex: 3,
    justifyContent: "flex-end",
  },
  meContent: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  meImageContent: {
    maxHeight: 200,
  },
  meContentDate: {
    flex: 3,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  meContentMessage: {
    marginTop: 8,
    padding: 16,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
    flex: 1,
  },
});
