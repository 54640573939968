import {
  StatusBar,
  StatusBarStyle,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  useColorScheme,
  View,
  ViewStyle,
} from "react-native";
import React, { ReactNode, useEffect } from "react";
import { SafeAreaView, Text } from "../../components";

interface HeaderProps {
  style?: StyleProp<ViewStyle>;
  styleLeft?: StyleProp<ViewStyle>;
  styleCenter?: StyleProp<ViewStyle>;
  styleRight?: StyleProp<ViewStyle>;
  styleRightSecond?: StyleProp<ViewStyle>;
  title: string;
  subTitle?: string;
  onPressLeft?: () => void;
  onPressRight?: () => void;
  onPressRightSecond?: () => void;
  renderLeft?: () => ReactNode;
  renderRightSecond?: () => ReactNode;
  renderRight?: () => ReactNode;
  barStyle?: string;
}

const Header = (props: HeaderProps) => {
  const {
    style,
    styleLeft,
    styleCenter,
    styleRight,
    styleRightSecond,
    title,
    subTitle,
    onPressLeft,
    onPressRight,
    onPressRightSecond,
    renderLeft,
    renderRightSecond,
    renderRight,
    barStyle,
  } = props;

  const isDarkMode = useColorScheme() === "dark";

  useEffect(() => {
    let option: StatusBarStyle = isDarkMode ? "light-content" : "dark-content";
    StatusBar.setBarStyle(option, true);
  }, [isDarkMode, barStyle]);

  return (
    <SafeAreaView style={{ width: "100%" }} edges={["top", "left", "right"]}>
      <View style={[styles.contain, style]}>
        {/* Left Button*/}
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={[styles.contentLeft, styleLeft]}
            onPress={onPressLeft}
          >
            {renderLeft?.()}
          </TouchableOpacity>
        </View>
        {/* Left Button*/}

        {/* Title */}
        <View style={[styles.contentCenter, styleCenter]}>
          <Text headline numberOfLines={1}>
            {title}
          </Text>
          {subTitle !== "" && (
            <Text caption2 light>
              {subTitle}
            </Text>
          )}
        </View>
        {/* Title */}

        {/* Right Button */}
        <View style={styles.right}>
          <TouchableOpacity
            style={[styles.contentRightSecond, styleRightSecond]}
            onPress={onPressRightSecond}
          >
            {renderRightSecond?.()}
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.contentRight, styleRight]}
            onPress={onPressRight}
          >
            {renderRight?.()}
          </TouchableOpacity>
        </View>
        {/* Right Button */}
      </View>
    </SafeAreaView>
  );
};

export default Header;

const styles = StyleSheet.create({
  contain: { height: 45, flexDirection: "row" },
  contentLeft: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 20,
    width: 60,
  },
  contentCenter: {
    flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  contentRight: {
    justifyContent: "center",
    alignItems: "flex-end",
    paddingLeft: 10,
    paddingRight: 20,
    height: "100%",
  },
  contentRightSecond: {
    justifyContent: "center",
    alignItems: "flex-end",
    paddingLeft: 10,
    paddingRight: 10,
    height: "100%",
  },
  right: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
