export default {
  tabs: {
    home: "Home",
    messenger: "Messenger",
    reserve: "Reserve",
    order: "Order",
    calendar: "Calendar",
    profile: "Profile",
  },
  search: {
    title: "Search",
    search_nearby: "目前離你最近的寵物保姆",
    cancel: "Cancel",
    type_care_dog: "到府狗保姆",
    type_care_cat: "到府貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬照顧",
    type_care_baby_cat: "幼貓照顧",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬照顧",
    more: "了解更多",
  },
  enum: {
    currency: "$",
    top_sitter: "TOP 寵物保姆",
    area: "地區：東京都新宿區",
    lowest_price: "最低價格",
    hightest_price: "最高價格",
    hightest_rating: "最高評分",
    popularity: "人氣度",
    apply: "應用",
    filter: "篩選",
    reserve_now: "Reserve Now",
    rating: "評論",
    feedback: "回覆率",
    quick_reserve: "快速下訂",
    about_me: "關於我",
    other_service: "其他服務",
    service_photo: "服務照片",
    show_more: "查看更多",
    show_less: "查看較少",
    service: "服務",
    desceription: "詳細資訊",
    reviews: "評論",
    ratings: "則評論",
    continue: "繼續",
    cancel: "取消",
    done: "完成",
    setting: "設定",
    save: "保存",
    select: "選擇",
    sign_out: "登出",

    // calendar
    reserved: "有被預約",
    unreserve: "未預約",
    noreserve: "無法預約",
    full_reserve: "預約已滿",

    // month
    month: "月",
    // types
    type_care_dog: "狗保姆",
    type_care_cat: "貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬保姆",
    type_care_baby_cat: "幼貓保姆",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬保姆",
    more: "了解更多",
    // credit card
    credit_card: "信用卡",
    mobile_wallet: "手機錢包",
    // Visit to the depositary
    visit_to_the_depositary: "預約家訪",
  },
  license: {
    trimmer: "修剪師",
    domestic_dog_trainer: "家養犬訓練師",
    animal_assisted_welfare_worker: "動物輔助福利工作者",
  },
  page: {
    sitter_detail: "保姆細節",
    preview_reserve: "預約細節",
    payment_method: "付款方式",
    reserve_detail: "預約",
  },
  preview_reserve: {
    pets: "寵物",
    start_date: "開始時間",
    end_date: "結束時間",
    times_per_day: "每天次數",
    sub_total: "小計",
    dates_number: "天數",
    times_number: "次數",
    service_length: "服務時間",
    service_length_30mins: "服務時間（每 30 分鐘）",
    address: "地址",
    notes: "備註",
  },
  reserve: {
    current_reserve: "當前預約",
    history_reserve: "歷史預約",
    no_current_reserve: "尚無預約",
    no_history_reserve: "尚無歷史預約",
  },
  profile: {
    edit: "編輯個人檔案",
    sitter_profile: "保姆中心",
    pets: "Pets",
    coupon: "Coupon",
    support: "聯絡客服",
    invite_friends: "邀請朋友",
    setting: "Setting",
  },
  messenger: {
    type_message: "輸入訊息",
  },
  pets: {
    title: "我的寵物",
    health_status: "健康狀況描述",
    update_pet: "更新毛小孩檔案",
    add_pet: "新增您的毛小孩",
    select_avatar: "選擇毛小孩的大頭照",
    name: "毛小孩名字",
    name_placeholder: "毛毛、球球",
    health_status_placeholder: "請輸入毛小孩的健康狀況以及照顧時需要注意的事項",
    basic_information: "基本資料",
    category: "種類",
    select_category: "選擇種類",
    size: "尺寸",
    select_size: "選擇尺寸",
    birth_year: "出生年",
    select_birth_year: "選擇出生年",
    birth_month: "出生月（選填）",
    select_birth_month: "選擇出生月",
    gender: "性別",
    select_gender: "選擇性別",
    detail_information: "詳細資料",
    neutered_or_spayed: "是否結紮",
    select_neutered_or_spayed: "選擇是否結紮",
    regular_vaccination: "是否規律施打疫苗",
    select_regular_vaccination: "選擇是否規律施打疫苗",
    personalities: "個性（複選）",
    select_personalities: "選擇個性（複選）",
    category_DOG: "犬",
    category_CAT: "貓",
    emoji_category_DOG: "🐶",
    emoji_category_CAT: "🐱",
  },
  sitter_profile: {
    join_time: "加入時間",
    rating_and_reviews: "則評分",
    service_list: "服務列表",
    add_service: "新增服務",
    my_service_photo: "服務照片",
    upload_image: "上傳照片",
    edit: "編輯",
    account_balance: "帳戶餘額",
    wallet: "錢包",
    // 台灣帳戶
    bank_code: "銀行代碼",
    bank_code_placeholder: "013",
    bank_branch_code: "銀行分行代碼",
    bank_branch_code_placeholder: "0073",
    bank_account: "銀行帳號",
    bank_account_placeholder: "01234567890",
  },
  // "about_us": "About Us",
  // "who_we_are": "Who We Are?",
  // "what_we_do": "What We Do?",
  // "meet_our_team": "Meet Our Team",
  // "sologan_about_us": "A journey into the past",
  // "our_service": "Our Service",
  // "change_language": "Change Language",
  // "add_payment_method": "Add Payment Method",
  // "payment_method": "Payment Method",
  // "payment": "Payment",
  // "payment_completed_text": "Thank you. Your order has been completed. The SRR Secretariat will be in contact with you via email to confirm your registration and send you details for the meeting.",
  // "input_id": "Input ID",
  // "booking_id": "Booking ID",
  // "credit_card_detail": "Credit Card Details",
  // "domestic_card": "Domestic Card",
  // "credit_card": "Credit Card",
  // "internet_banking": "Internet Banking",
  // "mobile_wallet": "Mobile Wallets",
  // "card_information": "Card Information",
  // "credit_card_number": "Credit Card Number",
  // "valid_until": "Valid Until",
  // "digit_num": "3-Digit CCV",
  // "name_on_card": "Name on card",
  // "set_as_primary": "Set as primary",
  // "pay_now": "Pay Now",
  // "delete": "Delete",
  // "done": "Done",
  // "add": "Add",
  // "shutle_bus_search": "Shutle Bus Search",
  // "save": "Save",
  // "passenger": "Passenger",
  // "people": "People",
  // "hours": "Hours",
  // "filtering": "Filtering",
  // "lowest_price": "Lowest Price",
  // "hightest_price": "Hightest Price",
  // "hightest_rating": "Hightest Rating",
  // "popularity": "Popularity",
  // "filter": "Filter",
  // "apply": "Apply",
  // "from": "From",
  // "to": "To",
  // "price": "Price",
  // "avg_price": "AVG Price",
  // "results": "Results",
  // "shutle_bus": "Shutle Bus",
  // "booking_history": "Booking History",
  // "cancel": "Cancel",
  // "preview": "Preview",
  // "confirm": "Confirm",
  // "complete": "Complete",
  // "detail": "Detail",
  // "booking_detail": "Booking Detail",
  // "search_language": "Seach Language",
  // "search": "Search",
  // "home": "Home",
  // "booking": "Booking",
  // "message": "Message",
  // "news": "News",
  // "account": "Account",
  // "select_seats": "Select Seats",
  // "booked": "Booked",
  // "non_stop": "Non Stop",
  // "per_day": "Per Day",
  // "per_month": "Per Month",
  // "selected": "Selected",
  // "empty": "Empty",
  // "exit": "Exit",
  // "tickets": "Tickets",
  // "total_price": "Total Price",
  // "book_now": "Book Now",
  // "time": "Time",
  // "persons": "Persons",
  // "seat": "Seat",
  // "seat_number": "Seat Number",
  // "ticket_no": "Ticket No",
  // "confirm_date": "Confirm Date",
  // "total_fare": "Total Fare",
  // "download": "Download",
  // "car": "Car",
  // "economy": "Economy",
  // "reviews": "Reviews",
  // "input": "Input review",
  // "car_detail": "Car Detail",
  // "book": "Book",
  // "information": "Information",
  // "features": "Features",
  // "contact_host": "Contact Host",
  // "view_profile": "View Profile",
  // "profile": "Profile",
  // "activity": "Activity",
  // "edit_profile": "Edit Profile",
  // "change_password": "Change Password",
  // "password": "Password",
  // "input_password": "Input password",
  // "re_password": "Re-Password",
  // "password_confirm": "Password Confirm",
  // "check_out": "Check Out",
  // "reset": "Reset",
  // "billing_information": "Billing Information",
  // "street_address": "Street address",
  // "city": "city",
  // "post_code": "Post Code",
  // "post": "Post",
  // "country": "Country",
  // "contact_details": "Contact Details",
  // "contact_name": "Contact's Name",
  // "name": "Name",
  // "input_name": "Input name",
  // "email": "Email",
  // "input_email": "Input email",
  // "code": "Code",
  // "input_address": "Input address",
  // "phone_number": "Phone phone number",
  // "contact_us": "Contact Us",
  // "send": "Send",
  // "coupons": "Coupons",
  // "cruise": "Cruise",
  // "itinerary": "Itinerary",
  // "feedback": "Feedback",
  // "specials": "Specials",
  // "avg_night": "AVG/Night",
  // "day": "Day",
  // "ports": "Ports",
  // "arrive": "Arrive",
  // "depart": "Depart",
  // "mon": "Mon",
  // "tue": "Tue",
  // "wed": "Wed",
  // "thur": "Thur",
  // "sun": "Sun",
  // "todo_things": "Todo Things",
  // "show_more": "Show More",
  // "if_you_have_question": "If You Have Any Questions",
  // "tap_to_rate": "Tap a star to rate",
  // "durating": "Durating",
  // "rating": "Rating",
  // "duration": "Duration",
  // "days": "Days",
  // "night": "Night",
  // "cruise_search": "Cruise Search",
  // "currency": "Currency",
  // "up_comming_events": "Up Comming Events",
  // "recommend_for_you": "Recommend For You",
  // "event": "Event",
  // "date_time": "Date/Time",
  // "date": "Date",
  // "address": "Address",
  // "description": "Description",
  // "see_details": "See Details",
  // "facilities": "Facilities",
  // "you_may_also_like": "You May Also Like",
  // "person_ticket": "Person/Ticket",
  // "category": "Category",
  // "preview_booking": "Preview Booking",
  // "adults": "Adults",
  // "children": "Childrens",
  // "continue": "Continue",
  // "quess": "Quess",
  // "infants": "Infants",
  // "room_type": "Room Type",
  // "room": "Room",
  // "interio": "Interio",
  // "round_trip": "Round Trip",
  // "one_way": "One Way",
  // "avg_time": "AVG Time",
  // "transit": "Transit",
  // "search_flight": "Search Flight",
  // "flight": "Flight",
  // "gate": "Gate",
  // "booking_summary": "Booking Summary",
  // "all_charged_included": "All Charged Included",
  // "class": "Class",
  // "economic_class": " Economic Class",
  // "what_are_you_looking_for": "What're you looking for ?",
  // "promos_today": "Promos Today",
  // "tours": "Tour",
  // "let_find_tour": "Let find out what most interesting things",
  // "comming_event": "Up Comming Events",
  // "let_find_event": "What’s the Worst That Could Happen",
  // "promotion": "Promotion",
  // "let_find_promotion": "What’s the Worst That Could Happen",
  // "hotels": "Hotel",
  // "excellent": "Excellent",
  // "hotel_description": "Hotel Description",
  // "location": "Location",
  // "good_to_know": "Good To Know",
  // "hotel_information": "Hotel Information",
  // "facilities_of_hotel": "Facilities of Hotel",
  // "messages": "Messages",
  // "type_message": "Type message ...",
  // "messenger": "Messenger",
  // "more": "More",
  // "my_cards": "My Cards",
  // "primary": "Primary",
  // "page_not_found": "Page Not Found",
  // "this_page_not_found": "This Page Can’t Not Found",
  // "back": "Back",
  // "notification": "Notification",
  // "join_now": "Join Now",
  // "card_detail": "Card Detail",
  // "user_following": "User Following",
  // "top_experiences": "Top Experiences",
  // "feature_post": "Featured Posts",
  // "price_table": "Pricing Table",
  // "setting": "Setting",
  // "sign_out": "Sign Out",
  // "follow": "Follow",
  // "about_me": "About Me",
  // "reminders": "Reminders",
  // "work_progress": "Work Progress",
  // "reset_password": "Reset Password",
  // "email_address": "Email Address",
  // "years": "Years",
  // "total_guest": "Total Guest",
  // "search_history": "Search History",
  // "clear": "Clear",
  // "discover_more": "Discover More",
  // "refresh": "Refresh",
  // "recently_view": "Recently Viewed",
  // "search_bus": "Search Shuttle Bus",
  // "search_cruise": "Search Cruise",
  // "dynamic_system": "Dynamic System",
  // "always_on": "Always On",
  // "always_off": "Always Off",
  // "close": "Close",
  // "airplane": "Airplane",
  // "language": "Language",
  // "search_airplane": "Search Airplane",
  // "theme": "Theme",
  // "font": "Font",
  // "default": "Default",
  // "dark_theme": "Dark Theme",
  // "app_version": "App Version",
  // "sign_in": "Sign In",
  // "forgot_your_password": "Forgot your password?",
  // "sign_up": "Sign Up",
  // "packages": "Packages",
  // "travel_agency": "Travel Agency",
  // "participants": "Participants",
  // "pick_your_destication": "Picking your travel destinations",
  // "login_facebook": "Login with Facebook",
  // "not_have_account": "Haven’t registered yet?",
  // "input_title": "Input title",
  // "all": "All",
  // "music": "Music",
  // "sports": "Sports",
  // "shows": "Shows",
  // "discounts": "Discounts",
  // "ticket_reserved": "Ticket Reserved Seating",
  // "ticket_vip": "Ticket VIP",
  // "ticket_general": "Ticket General Admission",
  // "sold_out": "Sold Out",
  // "hour_ago": "Hours ago",
  // "views": "Views",
  // "people_like_this": "People like this",
  // "total_ticket": "Total Ticket",
  // "show_qr_code_title": "At The Entrance, Show The QR Code Below",
  // "thanks": "Thanks You",
  // "check_in_from": "Check in from",
  // "check_out_from": "Check out from",
  // "replay": "Replay",
  // "basic_package": "Basic Package",
  // "standard_package": "Standard Package",
  // "premium_package": "Premium Package",
  // "car_sologan": "Sports & Luxury Cars",
  // "post_title": "Ready Fast For Fall Leaf Viewing Trips?",
  // "check_in": "Check In",
  // "bus_name": "Bus Name",
  // "depart_time": "Depart Time",
  // "arrive_time": "Arrive Time",
  // "from_person": "From/Person",
  // "ticket_sale": "Ticket Sales",
  // "bus": "Bus"
};
