/* eslint-disable camelcase */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BaseSetting } from ".";
/**
 * init language
 */
i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: BaseSetting.resourcesLanguage,
  lng: BaseSetting.defaultLanguage,
  fallbackLng: BaseSetting.defaultLanguage,
  defaultNS: "common",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});
