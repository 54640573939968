import { t } from "i18next";
import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { Text } from "../components";
interface ProfilePerformanceProps {
  style?: StyleProp<ViewStyle>;
  contentLeft?: any;
  contentCenter?: any;
  contentRight?: any;
  data?: any;
  type?: any;
  flexDirection?: any;
}
const ProfilePerformance = (props: ProfilePerformanceProps) => {
  const renderValue = (type: any, value: any) => {
    switch (type) {
      case "primary":
        return (
          <Text title3 semibold primaryColor>
            {value}
          </Text>
        );
      case "small":
        return (
          <Text body1 semibold>
            {value}
          </Text>
        );
      default:
        return (
          <Text headline semibold>
            {value}
          </Text>
        );
    }
  };

  const renderTitle = (type: any, value: any) => {
    switch (type) {
      case "primary":
        return (
          <Text body2 grayColor>
            {t(`enum:${value}`)}
          </Text>
        );
      case "small":
        return (
          <Text caption1 grayColor>
            {t(`enum:${value}`)}
          </Text>
        );
      default:
        return (
          <Text body2 grayColor>
            {t(`enum:${value}`)}
          </Text>
        );
    }
  };

  const {
    style,
    contentLeft,
    contentCenter,
    contentRight,
    data,
    type,
    flexDirection,
  } = props;

  switch (flexDirection) {
    case "row":
      return (
        <View style={[styles.contain, style]}>
          {data.map((item: any, index: any) => {
            if (index == 0) {
              return (
                <View style={[styles.contentLeft, contentLeft]} key={index}>
                  {renderValue(type, item.value)}
                  {renderTitle(type, item.title)}
                </View>
              );
            } else if (index == data.length - 1) {
              return (
                <View style={[styles.contentRight, contentRight]} key={index}>
                  {renderValue(type, item.value)}
                  {renderTitle(type, item.title)}
                </View>
              );
            } else {
              return (
                <View style={[styles.contentCenter, contentCenter]} key={index}>
                  {renderValue(type, item.value)}
                  {renderTitle(type, item.title)}
                </View>
              );
            }
          })}
        </View>
      );
    case "column":
      return (
        <View style={[{ justifyContent: "space-between", flex: 1 }, style]}>
          {data.map((item: any, index: any) => (
            <View style={styles.itemInfor} key={index}>
              {renderTitle(type, item.title)}
              {renderValue(type, item.value)}
            </View>
          ))}
        </View>
      );
    default:
      return (
        <View style={[{ justifyContent: "space-between", flex: 1 }, style]}>
          {data.map((item: any, index: any) => (
            <View style={styles.itemInfor} key={index}>
              {renderTitle(type, item.title)}
              {renderValue(type, item.value)}
            </View>
          ))}
        </View>
      );
  }
};

export default ProfilePerformance;

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
  },
  contentLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  contentCenter: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  contentRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  itemInfor: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
});
