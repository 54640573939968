import { StyleSheet, View, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import { Text, Button } from "../index";
import { BaseColor, useTheme } from "../../config";
import Modal from "react-native-modal";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5";
import { SortOptions, SortSelected } from "../../fakedata";

const FilterSort = (props: any) => {
  const { style, modeView, onFilter, onChangeView, labelCustom } = props;
  const { colors } = useTheme();
  const { t } = useTranslation();
  const backgroundColor = colors.background;
  const cardColor = colors.card;
  const [sortSelected, setSortSelected] = useState(SortSelected);
  const [modalVisible, setModalVisible] = useState(false);
  const [sortOption, setSortOption] = useState(
    SortOptions.map((item: any) => {
      return {
        ...item,
        checked: item?.value === sortSelected.value,
      };
    }),
  );

  const onSelectFilter = (selected: any) => {
    setSortOption(
      SortOptions.map((item: any) => {
        return {
          ...item,
          checked: item?.value == selected.value,
        };
      }),
    );
  };

  const onOpenSort = () => {
    setModalVisible(true);
    setSortOption(
      SortOptions.map((item: any) => {
        return {
          ...item,
          checked: item?.value === sortSelected.value,
        };
      }),
    );
  };

  const onApply = () => {
    const { onChangeSort } = props;
    const sorted = sortOption.filter((item: any) => item?.checked);
    if (sorted.length > 0) {
      setSortSelected(sorted[0]);
      setModalVisible(false);
      onChangeSort(sorted[0]);
    }
  };

  const iconModeView = (modeView: string) => {
    switch (modeView) {
      case "block":
        return "square";
      case "grid":
        return "th-large";
      case "list":
        return "th-list";
      default:
        return "th-list";
    }
  };

  const customAction =
    modeView !== "" ? (
      <TouchableOpacity onPress={onChangeView} style={styles.contentModeView}>
        <Icon
          name={iconModeView(modeView)}
          size={16}
          color={BaseColor.grayColor}
          solid
        />
      </TouchableOpacity>
    ) : (
      <Text headline grayColor numberOfLines={1} style={styles.contentModeView}>
        {labelCustom}
      </Text>
    );
  return (
    <View style={[styles.contain, style, { backgroundColor }]}>
      <Modal
        isVisible={modalVisible}
        onSwipeComplete={() => {
          setModalVisible(false);
          setSortOption(props.sortOption);
        }}
        swipeDirection={["down"]}
        style={[styles.bottomModal]}
      >
        <View
          style={[styles.contentFilterBottom, { backgroundColor: cardColor }]}
        >
          <View style={styles.contentSwipeDown}>
            <View style={styles.lineSwipeDown} />
          </View>
          {sortOption.map((item: any) => (
            <TouchableOpacity
              style={[
                styles.contentActionModalBottom,
                { borderBottomColor: colors.border },
              ]}
              key={item?.value}
              onPress={() => onSelectFilter(item)}
            >
              <Text body2 semibold primaryColor={item?.checked}>
                {t("enum:" + item?.text)}
              </Text>
              {item?.checked && (
                <Icon name="check" size={14} color={colors.primary} />
              )}
            </TouchableOpacity>
          ))}
          <Button
            full
            style={{ marginTop: 10, marginBottom: 20 }}
            onPress={() => onApply()}
          >
            {t("enum:apply")}
          </Button>
        </View>
      </Modal>
      <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={() => onOpenSort()}
      >
        <Icon
          name={sortSelected.icon}
          size={16}
          color={BaseColor.grayColor}
          solid
        />
        <Text headline grayColor style={{ marginLeft: 5 }}>
          {t("enum:" + sortSelected.text)}
        </Text>
      </TouchableOpacity>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {customAction}
        <View style={styles.line} />
        <TouchableOpacity onPress={onFilter} style={styles.contentFilter}>
          <Icon name="filter" size={16} color={BaseColor.grayColor} solid />
          <Text headline grayColor style={{ marginLeft: 5 }}>
            {t("enum:filter")}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default FilterSort;

const styles = StyleSheet.create({
  contain: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    width: 1,
    height: 14,
    backgroundColor: BaseColor.grayColor,
    marginLeft: 10,
  },
  contentModeView: {
    width: 30,
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  contentFilter: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  contentFilterBottom: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 20,
  },
  contentSwipeDown: {
    paddingTop: 10,
    alignItems: "center",
  },
  lineSwipeDown: {
    width: 30,
    height: 2.5,
    backgroundColor: BaseColor.dividerColor,
  },
  contentActionModalBottom: {
    flexDirection: "row",
    paddingVertical: 15,
    justifyContent: "space-between",
    borderBottomWidth: 1,
  },
});
