import React, { useState } from "react";
import { RefreshControl, FlatList, View } from "react-native";
import { BaseStyle, useTheme } from "../../config";
import { Header, SafeAreaView, ListThumbCircle } from "../../components";
import { MessagesData } from "../../fakedata";
import { useTranslation } from "react-i18next";

export default function Messenger({ navigation }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [refreshing] = useState(false);
  const [messenger] = useState(MessagesData);

  return (
    <View style={{ flex: 1 }}>
      <Header title={t("tabs:messenger")} subTitle={""} />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        <FlatList
          style={{ paddingHorizontal: 15 }}
          refreshControl={
            <RefreshControl
              colors={[colors.primary]}
              tintColor={colors.primary}
              refreshing={refreshing}
              onRefresh={() => {}}
            />
          }
          data={messenger}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item, index }) => (
            <ListThumbCircle
              style={{ marginVertical: 5 }}
              onPress={() => {
                navigation.navigate("Messages", {
                  messages: {},
                });
              }}
              image={item.image}
              txtLeftTitle={item.user}
              txtContent={item.message}
              txtRight={item.date}
              txtRightNumber={item.number}
            />
          )}
        />
      </SafeAreaView>
    </View>
  );
}
