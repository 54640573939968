import React from 'react'

type Props = {}

const PrivacyPolicyScreen = (props: Props) => {
  return (
      <div className="container text-center my-3" style={{padding: 20}}>
        <h1 className="my-3 text-center">プライバシーポリシー</h1>
        <div className="text-left">
          <ul className="pl-0">
            <li>
              <h3 className="my-2">序章</h3>
              <ul>
                <li>
                  「Fluv毛小愛プラットフォーム」を利用することを歓迎します。Fluv毛小愛は、適用されるプライバシー及び個人データ保護関連法規（以下、「プライバシー法」と呼びます）に基づく責任を真剣に考えており、Fluv毛小愛ウェブサイトのすべてのユーザーのプライバシーと関心事を尊重することに努めています。私たちは、あなたが私たちに提供する個人データが非常に重要であることを理解しており、あなたの個人データを適切に管理、保護、および処理することが私たちの責任であると考えています。このプライバシーポリシーは、現在および将来、どのようにしてあなたから提供されたデータを収集、利用、開示および/または処理するか、そして私たちのサービスを利用する前に賢明な判断を下すために役立つ情報を提供することを目的としています。このプライバシーポリシーを注意深くお読みください。プライバシーの慣行や取引に関する質問がある場合は、このプライバシーポリシーの最後にある「質問、懸念事項、苦情？お問い合わせください」のセクションを参照してください。
                </li>
                <li>
                  「個人情報」とは、実際に存在するかどうかにかかわらず、特定の個人に関連するデータで、その個人をそのデータまたはそのデータと他の情報を持っているまたはアクセス可能な組織を通じて識別することができます。個人情報の一般的な例には、名前、身分証明書番号、連絡先情報が含まれます。
                </li>
                <li>
                  サービスを利用する場合、アカウントを登録する場合、当社のウェブサイトを訪れる場合、またはサービスにアクセスする場合、あなたはこのプライバシーポリシーに記載された慣行、要求、および/またはポリシーを認識し、受け入れることに同意し、私たちがここに述べた個人情報を収集、利用、開示および/または処理することに同意することになります。このプライバシーポリシーに記載された方法で個人情報の処理に同意しない場合は、私たちのサービスを使用しないでください。プライバシーポリシーが変更された場合、変更されたプライバシーポリシーをウェブサイトに掲載します。私たちは、このプライバシーポリシーをいつでも変更する権利を留保します。
                </li>
              </ul>
            </li>
            <li>
              <h3 className="my-2">Fluv毛小愛はいつ個人情報を収集するのですか？</h3>
              <ol>
                <li>
                  私たちは以下のような場合にあなたの個人情報を収集することがあります：
                  <ol type="a">
                    <li className="en-icon">
                      サービスを登録および/または利用する際、または私たちにアカウントを開設する際；
                    </li>
                    <li className="en-icon">
                      オンラインまたは紙のフォームを通じてフォームを提出する場合、申請書を含むがこれに限定されない、当社の製品またはサービスに関連するその他のフォームについて；
                    </li>
                    <li className="en-icon">
                      契約に署名する場合、または他のドキュメントや情報を提供する場合、または私たちの製品やサービスを使用する際；
                    </li>
                    <li className="en-icon">
                      電話（録音される場合があります）、手紙、ファックス、対面での会議、ソーシャルメディアプラットフォーム、電子メールなどの手段を通じて私たちとやり取りする場合；
                    </li>
                    <li className="en-icon">
                      電子サービスを使用する場合、または私たちのアプリケーションを通じて、または私たちのウェブサイト上のサービスを利用して私たちとやり取りする場合。これには、Cookieがデプロイされる場合も含まれます（あなたが私たちのアプリケーションまたはウェブサイトとやり取りする際）；
                    </li>
                    <li className="en-icon">
                      当社のサービスを通じて取引を行う場合；
                    </li>
                    <li className="en-icon">私たちにフィードバックまたは苦情を提供する場合；</li>
                    <li className="en-icon">コンテストに登録する場合；または</li>
                    <li className="en-icon">
                      任意の理由で私たちに個人情報を提出する場合。
                    </li>
                  </ol>
                  上記は全てを網羅しているわけではなく、あなたの個人情報が収集される可能性のある一部の一般的なインスタンスをリストアップしたものです。
                </li>
                <li>
                  移動アプリケーションまたは私たちのウェブサイトを訪れる、使用する、またはそれとやり取りする際、私たちはさまざまな技術を使用して特定の情報を自動的にまたは受動的に収集する場合があります。これらの技術は、あなたのデバイスにダウンロードされる可能性があり、あなたのデバイスの設定を設定または変更することがあります。私たちが収集する情報には、インターネットプロトコル（IP）アドレス、コンピュータ/モバイルデバイスのオペレーティングシステムとブラウザータイプ、モバイルデバイスのタイプ、モバイルデバイスの特性、モバイルデバイスの一意のデバイス識別子（UDID）またはモバイル機器識別子（MEID）、参照サイトのアドレス（該当する場合）、および私たちのウェブサイトおよびモバイルアプリケーションで訪れたページおよび訪問時間が含まれます。私たちは以下で定義される「目的」のために、これらの情報を収集、使用、開示および/または処理します。
                </li>
                <li>
                  私たちのモバイルアプリケーションは、GPS、Wi-Fiなどの技術を使用して、あなたのモバイルデバイスの正確な位置情報を収集する場合があります。私たちは、1つまたは複数の「目的」のためにこれらの情報を収集、使用、開示および/または処理します。これには、位置に基づくサービスを要求する場合、あなたの位置に基づいて関連するコンテンツを提供する場合、および私たちのモバイルアプリケーションに含まれるサービスを通じて他のユーザーとあなたの位置を共有する場合が含まれます。あなたは、ほとんどのモバイルデバイスのデバイス設定を通じて、私たちがあなたの位置情報を取得する許可を撤回することができます。モバイルデバイスの位置サービスを無効にする方法について質問がある場合は、モバイルデバイスのサービスプロバイダーまたはデバイスメーカーにお問い合わせください。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">Fluv毛小愛はどのような個人情報を収集するのですか？</h3>
              <ol>
                <li>
                  Fluv毛小愛が収集する個人情報には、以下が含まれますがこれに限定されません：
                  <ul>
                    <li>名前；</li>
                    <li>電子メールアドレス；</li>
                    <li>生年月日；</li>
                    <li>請求先住所；</li>
                    <li>銀行口座および支払い情報；</li>
                    <li>電話番号；</li>
                    <li>性別；</li>
                    <li>
                      ユーザーがサービスまたはウェブサイトを登録または使用する際に提供されるユーザーに関するその他の情報、およびユーザーが私たちのサービスまたはウェブサイトを使用する方法に関連する情報、およびユーザーの相互作用コンテンツに関連する情報。
                    </li>
                  </ul>
                </li>
                <li>
                  上記の情報/個人情報の収集を望まない場合は、いつでも書面による通知を通じて私たちの「データ保護責任者」に連絡して、オプトアウトを選択することができます。オプトアウトに関する詳細情報は、以下の「如何にしてオプトアウト、削除、アクセス要求、または提供された情報を修正するか？」のセクションで見つけることができます。ただし、私たちがあなたの個人情報を収集しないようにすることを選択した場合、またはあなたの個人情報をこのプライバシーポリシーに記載された方法で収集、使用、または処理することに同意しない場合、サービスの使用に影響を与える可能性があります。たとえば、あなたの位置情報の収集を許可しないことにより、位置に基づく機能が無効になる可能性があります。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">アカウントの設定</h3>
              <ol>
                <li>
                  特定の機能を使用するためには、ユーザーアカウントを作成する必要があります。これには、特定の個人情報の提出が必要です。アカウントを登録および作成する際、私たちはあなたの名前、電子メールアドレス、および選択したユーザー名を要求します。また、電話番号、電子メールアドレス、配送先住所、写真付き身分証明書、銀行口座詳細、年齢、生年月日、性別、および興味など、あなた自身に関連する特定の情報も要求します。アカウントが有効になると、ユーザー名とパスワードを選択します。システムは、あなたのユーザー名とパスワードを使用して、あなたが安全にアクセスおよび管理できるようにします。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">ウェブページの閲覧</h3>
              <ol>
                <li>
                  ほとんどのウェブサイトと同様に、あなたのコンピューターは私たちのウェブサイトを閲覧する際に個人情報を含む可能性のある情報を送信し、ウェブサーバーはその情報を記録します。これには通常、あなたのコンピューターのIPアドレス、オペレーティングシステム、ブラウザー名/バージョン、参照ページ、要求されたページ、日付/時間が含まれます。時々、Cookieを使用して以前の訪問情報を記憶するのに役立ちます（ブラウザの設定で無効にすることができます）。ログインすると、この情報はあなたの個人アカウントに関連付けられます。この情報はまた、訪問者が当社のウェブサイトを使用する方法を理解するのに役立つ匿名の統計データにも含まれます。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">COOKIE</h3>
              <ol>
                <li>
                  私たちは時々「Cookie」または他の機能を実行し、私たちまたは第三者が情報を収集または共有し、私たちが提供するウェブサイトおよびサービスを改善するのに役立てることがあります。または新しいサービスおよび機能を提供するのに役立てます。「Cookie」とは、私たちがあなたのコンピューターまたはモバイルデバイスに送信する識別子であり、私たちがあなたのコンピューターまたはデバイスを識別し、私たちのサービスまたはウェブサイトの使用方法および訪問時間、訪問回数を教えてくれます。また、私たちのウェブサイトでのアクティビティを追跡します。私たちは、Cookie情報を個人情報とリンクすることがあります。Cookieはまた、あなたが選んだ商品および閲覧したページに関連する情報にリンクされています。この情報は、あなたのショッピングカートなどの目的で使用されます。Cookieはまた、あなたの興味に関連する特定のコンテンツを提供し、ウェブサイトの使用状況を監視するために使用されます。
                </li>
                <li>
                  Cookieの使用を拒否する場合は、ブラウザーの適切な設定を選択してください。ただし、そうすると、当サイトまたはサービスの全機能を利用できない可能性があることに注意してください。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">コンテンツおよび広告の表示およびダウンロード</h3>
              <ol>
                <li>
                  当社のウェブサイト上でコンテンツや広告を見たり、サービスを通じて他のソフトウェアにアクセスしたりする場合、私たちに送信される情報は、ウェブページを閲覧する場合に送信される情報とほとんど同じです（IPアドレス、オペレーティングシステムなどを含むがこれに限定されない）；ただし、コンテンツおよび広告の表示と関連する情報、および/またはサービスおよびウェブサイトにインストールされたソフトウェアに関連する情報および時間が異なります。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">コミュニティとサポート</h3>
              <ol>
                <li>
                  私たちは、電子メール、SMS、および意見フォームを通じてカスタマーサポートを提供します。カスタマーサポートを提供するために、私たちはあなたの電子メールアドレスと携帯電話番号を要求します。カスタマーサポートリクエストから受け取った情報（電子メールを含むがこれに限定されない）は、カスタマーサポートサービスの提供のためにのみ使用され、私たちはその情報を第三者に送信したり、第三者と共有したりしません。
                </li>
                <li>
                  また、私たちのコミュニティフォーラムで質問を投稿したり、他のユーザーの質問に回答したりすることもできます。私たちのフォーラムとメッセージングサービスを使用することで、私たちのコミュニティに参加することができます。この目的のために、私たちはあなたのユーザーID、連絡先リスト、およびステータスメッセージなどの情報を保持します。さらに、これらのサービスおよび将来導入される類似のサービスでは、私たちがあなたのユーザーIDおよびパスワード情報を保持する必要がある場合があります。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">アンケート調査</h3>
              <ol>
                <li>
                  私たちは時々、ユーザーに情報を提供してもらうためのアンケート調査を実施することがあります。これらのアンケート調査への参加は完全に任意であり、あなたが私たちに情報を開示するかどうかを選択することができます。要求される情報には、連絡先情報（たとえば、あなたの電子メールアドレス）およびペットに関する統計情報が含まれることがあります。調査情報は、サービスの使用状況と満足度を監視または改善するために使用され、第三者に送信されることはありません。ただし、アンケート調査を管理するか、アンケート調査に対応するためのアクションを実施する契約者を除きます。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">私たちがあなたから提供された情報をどのように使用するのか？</h3>
              <ol>
                <li className="use-treaty-style">
                  私たちは、以下の1つまたは複数の目的のために、あなたとあなたのペットの個人情報を収集、使用、開示および/または処理することがあります：
                  <ol type="a">
                    <li className="en-icon">
                      あなたが私たちに提出した申請/私たちとの取引を考慮し、または/または処理するため、またはあなたがこのサービスを通じて第三者と行う取引または通信；
                    </li>
                    <li className="en-icon">
                      私たちのサービスおよびウェブサイトの管理、運営、提供、および/または処理、あなたと私たちとの関係、およびあなたが私たちに開設したユーザーアカウント；
                    </li>
                    <li className="en-icon">
                      サービスの管理、運営、処理、および提供およびサービスの提供を促進するため、あなたの好みの設定を覚えておくことを含むがこれに限定されない；
                    </li>
                    <li className="en-icon">
                      サービスを通じてあなたの経験をパーソナライズする：あなたの興味と好みに基づいてコンテンツを表示し、あなたがアカウントにアクセスし、情報を私たちに提出するためのより迅速な方法を提供し、必要に応じて私たちがあなたに連絡することを許可する；
                    </li>
                    <li className="en-icon">
                      トランザクションに応答し、処理する、または完了させ、特定の製品およびサービスに対するあなたの要求に応じ、サービスの問題および通常でないアカウントの操作についてあなたに通知する；
                    </li>
                    <li className="en-icon">
                      私たちの「利用規約」または適用可能ないずれかのユーザーライセンス契約を実行する；
                    </li>
                    <li className="en-icon">
                      個人の安全および権利、財産、または他の人々の安全を保護する；
                    </li>
                    <li className="en-icon">身元確認および/または検証；</li>
                    <li className="en-icon">
                      ソフトウェアの更新および/またはその他の更新およびサポートを管理および管理することが必要な場合に、サービスがスムーズに機能するようにする；
                    </li>
                    <li className="en-icon">
                      カスタマーサービスを処理または促進し、あなたの指示を実行し、あなたまたはあなたを代表して（またはあなたまたはあなたを代表していると主張する人から）提出されたすべての問い合わせを処理または応答する；
                    </li>
                    <li className="en-icon">
                      あなたと私たちとの関係の実行および/または管理、またはあなたのサービスの使用の目的のために、音声通話、テキストメッセージおよび/またはファックスメッセージ、電子メールおよび/または郵便またはその他の方法であなたに連絡または通信する、たとえばサービスに関連する管理情報を伝えるために限定されない。あなたは、郵便での手紙、ドキュメント、および通知を送る方法を通じて上記の通信を行うことを認識し、同意します。配達プロセス中に封筒/郵便パッケージの外部にあなたの特定の個人情報を開示する可能性があります。
                    </li>
                    <li className="en-icon">
                      他のユーザーが当サイト上であなたにプライベートメッセージを送信するか、あなたに対するコメントを投稿する場合に通知する；
                    </li>
                    <li className="en-icon">
                      リサーチ、分析、および開発活動（データ分析、アンケート調査、製品およびサービスの開発および/またはプロファイリングを含むがこれに限定されない）を行い、あなたのサービスの使用状況を分析し、私たちのサービスまたは製品を改善し、/またはあなたの顧客体験を強化する；
                    </li>
                    <li className="en-icon">
                      目標とする聴衆の規模と構成を確認し、彼らがサービスでの体験を理解するために監査および調査を許可する；
                    </li>
                    <li className="en-icon">
                      あなたの事前の同意を得て、マーケティングまたはそれに関連する目的のために、郵便での手紙、電子メール、位置に基づくサービス、またはその他のモードを通じて、Fluv毛小愛（および/またはその関連企業または関連会社）が現在販売、マーケティング、または促進している可能性のある製品および/またはサービス（Fluv毛小愛が協力または共同で提供する可能性のある第三者の製品および/またはサービスを含むがこれに限定されない）に関するマーケティングおよびプロモーション情報および資料を送信する；
                    </li>
                    <li className="en-icon">
                      法的手続きに応じるか、関連する管轄地域のいずれかの適用可能な法律、政府または規制要求に従うため、Fluv毛小愛またはその関連企業または関連会社に拘束力のあるいずれかの法的規定に基づいて開示要求に従うために含まれるがこれに限定されない；
                    </li>
                    <li className="en-icon">
                      内部および法定の報告および/または記録保持要求のために統計データと研究結果を生成する；
                    </li>
                    <li className="en-icon">
                      法的または規制上の義務に基づく、または当社のリスク管理手順（法的規定に基づく、または当社が実施するもの）に従って、デューデリジェンスまたはその他のフィルタリングアクション（バックグラウンドチェックを含むがこれに限定されない）を実施する；
                    </li>
                    <li className="en-icon">
                      当社のサービスまたはFluv毛小愛のビジネスを監査する；
                    </li>
                    <li className="en-icon">
                      詐欺、違法行為、過失、または不適切な行動を防止または調査するため、これらの行為があなたのサービスの使用と関連があるかどうか、あなたと私たちとの関係から生じるかどうか、または前述の行為の疑いがあるかどうかを理解するため；
                    </li>
                    <li className="en-icon">
                      災害復旧またはその他の目的のためにあなたの個人情報を保存、ホスティング、バックアップする（あなたの管轄地域内または外で）；
                    </li>
                    <li className="en-icon">
                      企業資産取引または潜在的な企業資産取引を処理および/または促進するため、その取引にはFluv毛小愛、またはFluv毛小愛の関連会社または関連企業のみが含まれる場合もあれば、Fluv毛小愛および/またはFluv毛小愛の1つ以上の関連会社または関連企業が含まれ、その他の第三者組織も取引に参加する場合もあります。「企業資産取引」とは、組織、組織の一部、または組織のいずれかのビジネスまたは資産の取得、販売、リース、統合、合併、またはその他の取得、処分、または資金調達活動を指します；および/または私たちがあなたの同意を得た時に、あなたに通知されたその他の目的。
                      <br />
                      （以上を総称して「目的」とします）。
                    </li>
                  </ol>
                </li>
                <li>
                  私たちがあなたの個人情報を収集、使用、開示または処理する目的は、その時の状況に依存するため、その目的は上記の項目に表示されない場合があります。ただし、関連する規制が私たちがあなたの同意を得ないで適用可能なデータを処理することを許可する場合を除き、私たちはあなたの同意を得た時にその他の目的をあなたに通知します。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">サービス情報の共有</h3>
              <ol>
                <li>
                  私たちのサービスでは、Fluv毛小愛が関与しない状況で、ユーザー間で個人情報を共有することができます。典型적な取引では、ユーザーは互いの名前、ユーザーID、電子メールアドレス、その他の連絡先および郵送情報にアクセスできます。私たちの利用規約は、他のユーザーの個人情報を持っているユーザー（以下、「受信者」と呼びます）
                  に対して、（i）すべての適用されるプライバシー法を遵守すること、（ii）
                  該当する他のユーザー（以下、「開示者」と呼びます）
                  が受信者のデータベースから自分自身を削除することを許可すること、および（iii）
                  開示者が受信者が収集した開示者に関連する情報を確認することを許可することを要求します。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">Fluv毛小愛はどのように顧客情報を保護するのですか？</h3>
              <ol>
                <li>
                  私たちは、あなたの個人情報が私たちのシステム上で安全であることを確保するためにさまざまな安全措置を講じています。ユーザーの個人情報は、特定のシステムへのアクセス権を持つ限られた従業員のみがアクセスできる安全なネットワークの後ろに含まれています。私たちは、プライバシー法および/または他の適用される法律に従って個人情報を保持します。つまり、私たちが合理的に考えるに、（i）その個人情報を保持することが個人情報を収集する目的にはもはや適合しない場合、および（ii）
                  その個人情報を法的またはビジネス目的のために保持する必要がなくなった場合、私たちはすぐにあなたの個人情報を破棄するか、匿名化を行います。あなたが当サイトの使用を停止した場合、またはあなたが当サイトおよび/またはサービスの使用権が終了した場合でも、私たちはこのプライバシーポリシーおよびプライバシー法で定められた義務に従って、あなたの個人情報を保持、使用、および/または開示し続けます。適用される法律により、私たちは事前の通知なしにあなたの個人情報を安全に処分することが許可されています。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">
                Fluv毛小愛は訪問者から収集した情報を第三者に開示しますか？
              </h3>
              <ol>
                <li>
                  ビジネスを運営する際に、私たちは上述の1つまたは複数の目的のために、第三者サービスプロバイダー、代理店、および/または私たちの関連企業または関連会社および/または他の第三者にあなたの個人情報を開示する必要があります。その第三者サービスプロバイダー、代理店、および/または関連企業または関連会社および/またはその他の第三者は、私たちを代表してまたは他の方法であなたの個人情報を処理する場合があります。その第三者には以下が含まれますが、これに限定されません：
                </li>
                <ol type="a">
                  <li className="en-icon">
                    私たちの子会社、関連企業、および関連会社；
                  </li>
                  <li className="en-icon">
                    私たちのビジネスをサポートする第三者。これには、私たちに管理またはその他のサービスを提供するエンティティが含まれます。例えば、郵便会社、電気通信会社、情報技術会社、およびデータセンター；
                  </li>
                  <li className="en-icon">
                    Fluv毛小愛の一部または全部の資産の売却、賃貸、再編、再構成、解散、またはその他の売却または移転のバイヤーまたはその他の後継者（事業の継続の形態であるか、または破産、清算、または類似の手続きの一環であるかにかかわらず）、Fluv毛小愛が所有する、本サービスのユーザーに関連する個人情報も移転される資産に含まれます；またはFluv毛小愛またはそのいずれかの関連企業または関連会社が関与する企業資産取引の当事者；および
                  </li>
                  <li className="en-icon">
                    私たちが1つまたは複数の目的のために個人情報を開示する第三者で、その第三者が1つまたは複数の目的のために逆にあなたの個人情報を収集および処理する場合。
                  </li>
                </ol>
                <li>
                  これには、広告および計画プロバイダーとユーザーおよび彼らの本サービスの使用状況に関連する統計および人口統計情報の共有および使用が含まれる場合があります。これには、あなたの身元を明確に特定するか、あなたに関連する個人情報を発見するために使用できるデータが含まれない場合があります。
                </li>
                <li>
                  疑問を避けるために、プライバシー関連の法規またはその他の適用される法律が組織に、私たちのように、あなたの同意なしにあなたの個人情報を収集、使用、または開示することを許可する場合、そのような法律によって付与された権限は引き続き適用されるべきです。
                </li>
                <li>
                  私たちの責任ではない状況で、第三者がウェブサイトに送信される個人情報またはウェブサイトに含まれる個人情報を不法に傍受またはアクセスすることがあります。技術的な障害が発生するか、予期されるとおりに機能しない可能性があります。または、誰かが情報にアクセスし、濫用するか、誤用する可能性があります。ただし、私たちはプライバシー関連の法規の規定に従って、あなたの個人情報を保護するために合理的な安全対策を講じますが、絶対的な安全を保証することはできません。これには、私たちの責任ではない状況で、不満足なメンバーによる悪意のある精密なハッカー攻撃が原因で未承認の開示が発生する場合を含みますが、これに限定されません。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">未成年者に関する情報</h3>
              <ol>
                <li>
                  本サービスは、20歳未満の未成年者には適していません。私たちは故意に20歳未満の未成年者の個人情報または個人を特定できない情報を収集または保持せず、また、本ウェブサイトまたはその他のサービスのすべての部分は20歳未満の未成年者を対象としていません。私たちは、その未成年者が専用のすべてのアカウントを閉鎖し、20歳未満の未成年者によって提出されたと考えられるすべての個人情報を削除および/または削除します。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">第三者による情報収集</h3>
              <ol>
                <li>
                  このプラットフォームは、Google, Inc.（以下「Google」と呼びます）
                  が提供するデータ分析サービス - Google
                  Analyticsを使用しています。あなたのプラットフォームの使用に関連する情報はGoogleに送信され、Googleは米国にあるサーバーにその情報を保存します。Googleは、この情報を使用してあなたのプラットフォームの使用状況を評価し、プラットフォームの運営者にプラットフォームの活動に関するレポートを作成し、プラットフォームの活動およびインターネットの使用に関連するその他のサービスを提供します。Googleは、法律が要求する場合には、この情報を第三者に送信することがあります。または、Googleが情報を処理するために代理を務める第三者にこの情報を送信することがあります。Googleは、あなたのIPアドレスをGoogleが保有する他のデータと関連付けることはありません。
                </li>
                <li>
                  私たちと第三者は、時々、本サービスを通じて、または本サービスを通じてソフトウェアアプリケーションのダウンロードを提供することがあります。これらのアプリケーションは、個別にアクセスして第三者があなたの身元識別情報（あなたの名前、ユーザーID、コンピュータIPアドレス、またはその他の情報など）を表示することを許可する場合があります。これには、あなたが以前にインストールしたか、第三者のソフトウェアアプリケーションまたはウェブサイトによってあなたのためにインストールされたCookieなどが含まれます。さらに、これらのアプリケーションは、あなたに他の情報を直接第三者に提供するよう要求することがあります。Fluv毛小愛は、これらのアプリケーションを通じて提供される第三者の製品やサービスを所有または管理していません。私たちは、これらの第三者が彼らのウェブサイトまたは他の場所で公開している利用規約およびその他のポリシーを注意深く読むことをお勧めします。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">セキュリティと第三者サイトに関する免責事項</h3>
              <ol>
                <li>
                  私たちは、第三者のウェブサイトで提供された個人情報および/またはその他の情報のセキュリティを保証しません。あなたに関連する私たちが保有または管理する個人情報のセキュリティを維持するために、私たちはさまざまなセキュリティ対策を講じています。あなたの個人情報は、特定のシステムへのアクセス権を持つ限られた人員のみがアクセスできる安全なネットワークの後ろに保管されています。あなたが注文をするか、個人情報にアクセスするとき、私たちは安全なサーバーを使用するようになります。あなたが提供するすべての個人情報または機密情報は、私たちのデータベースに暗号化された形式で保存され、上記の人員のみがアクセスできます。
                </li>
                <li>
                  より高い価値を提供するために、私たちはさまざまな第三者のウェブサイトを当サイトにリンクすることを選択するか、第三者のウェブサイトを当サイトのフレームに配置する場合があります。また、訪問者に電子商取引およびその他のサービスおよび機能を提供するために、共同ブランドおよびその他の関係に参加することもあります。リンクされたウェブサイトは、個々に独立したプライバシーポリシーおよびセキュリティ対策を持っています。それらの第三者との関連があるにもかかわらず、私たちはそれらのリンクされたウェブサイトに対して制御権を持っておらず、それぞれのウェブサイトは私たちの支配を超えた個別のプライバシーおよびデータ収集慣行を持っています。私たちは、共同ブランドのパートナーまたは第三者のウェブサイト（当サイト上でまたは当サイトを通じて提供される場合であっても）によって収集されたデータを受け取ることがありません。
                </li>
                <li>
                  したがって、私たちはこれらのリンクされたウェブサイトのコンテンツ、セキュリティ対策（またはその不足）、および活動に対して責任または義務を負いません。これらのリンクされたウェブサイトは便宜上提供されているため、これらのウェブサイトにアクセスするときは自己責任で行う必要があります。それにもかかわらず、私たちは当サイトおよびそのリンクの完全性を維持しようと努力していますので、これらのリンクされたウェブサイトに関連するフィードバック（特定のリンクが機能しない状況を含むがこれに限定されない）を歓迎します。
                </li>
              </ol>
            </li>
            {/* <li>
                            <h3 className="my-2">Fluv毛小愛はあなたの情報を海外に送信しますか？</h3>
                            <ol>
                                <li>あなたの個人情報および/または情報は、あなたの国/地域外の場所に送信され、その場所で保存または処理されることがあります。ほとんどの場合、あなたの個人情報は台湾で処理されますが、私たちのサーバーは台湾の国家/地域外の場所に設置されており、私たちの中央データベースも台湾の国家/地域外の場所で運営されています。Fluv毛小愛は、プライバシー関連の法規に従ってのみ、あなたの情報を国外に送信します。</li>
                            </ol>
                        </li> */}
            <li>
              <h3 className="my-2">
                如何にしてオプトアウト、削除、アクセス要求、または提供された情報を修正するか？
              </h3>
              <ol>
                <li>
                  オプトアウトおよび同意の撤回
                  <ol type="a">
                    <li className="en-icon">
                      電子メールの購読を変更したい場合は、以下のアドレスに電子メールを送信してください。ただし、電子メールは生産スケジュールの制御を受けるため、生成された電子メールを受信する可能性があります。
                    </li>
                    <li className="en-icon">
                      私たちが所有または管理するあなたに関連する個人情報の収集、使用、および/または開示をこれ以上望まない場合は、以下にリストされているアドレスに電子メールを送信してください。
                    </li>
                    <li className="en-icon">
                      あなたの明確な撤回指示を受け取り、あなたの身元を確認した後、私たちはあなたの撤回同意要求を処理し、その後はあなたが要求で述べた方法であなたの個人情報を収集、使用、および/または開示しなくなります。私たちがあなたの身元を確認できない場合やあなたの指示を理解できない場合は、あなたの要求を理解するために連絡を取ります。
                    </li>
                    <li className="en-icon">
                      ただし、同意を撤回すると、特定の法的結果が生じる可能性があります。この点で、あなたが私たちの個人情報の処理に同意しなくなった範囲に応じて、これは私たちがあなたにサービスを提供することができなくなることを意味するかもしれません、私たちはあなたの現在の関係および/または私たちとの契約を終了する必要があるかもしれません。状況に応じて、私たちは最終結果をあなたに通知します。
                    </li>
                  </ol>
                </li>
                <li>
                  アクセスおよび/または個人情報の修正を要求する
                  <ol type="a">
                    <li className="en-icon">
                      私たちのアカウントを持っている場合は、私たちのウェブサイトの「アカウント設定」ページを通じて、現在私たちが所有または管理する個人情報に自らアクセスおよび/または修正することができます。私たちのアカウントを持っていない場合は、私たちが所有または管理する現在の個人情報にアクセスおよび/または修正を要求するために書面による要求を提出することができます。あなたの要求を処理するのに十分な情報を提供する必要があります。したがって、以下にリストされているアドレスに電子メールを送信して、「データ保護責任者」にあなたの書面による要求を提出してください。
                    </li>
                    <li className="en-icon">
                      私たちの個人情報にアクセスしたり、そのコピーを要求することができます（Fluv毛小愛は必要かつ合理的な費用を請求することがあります）。私たちは、あなたが提供する十分な情報を受け取り、その要求を処理するのに十分である後の15日以内に関連する個人情報を提供します。私たちが前述の15日以内に関連する個人情報を提供できない場合、
                    </li>
                    <li className="en-icon">
                      あなたの個人情報を補足、訂正、削除、処理または利用の停止を要求することができます。私たちは、あなたが提供する十分な情報を受け取り、その要求を処理するのに十分である後：
                    </li>
                    <ol type="i">
                      <li className="en-icon">30日以内に処理を完了します；および</li>
                      <li className="en-icon">
                        Fluv毛小愛の事由により個人情報を訂正または補足する必要がある場合、私たちは訂正または補足後に法律に従って提供を受けた対象に通知します。
                      </li>
                    </ol>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">質問、懸念事項、苦情？お問い合わせください</h3>
              <ol>
                <li>
                  私たちのプライバシー慣行またはあなたとのサービスの取引に関して質問や懸念がある場合は、直接お問い合わせください：hello@ifluvyou.com
                </li>
                <li>
                  私たちがあなたの個人情報を処理する方法、または私たちがプライバシー法を遵守しているかどうかに関する苦情や不満がある場合は、お気軽にお問い合わせください。
                </li>
                <ul>
                  <li>電子メールでお問い合わせいただき、苦情や不満を提出してください。</li>
                  <li>
                    電子メール：hello@ifluvyou.com、受取人を「データ保護責任者」としてください。
                  </li>
                  <li>
                    すべての法的通知をhello@ifluvyou.comに送信し、受取人を「法務長」としてください。
                  </li>
                </ul>
                <li>
                  電子メールまたは手紙で苦情を提出する場合は、件名にプライバシー法に関する苦情であることを示してください。これにより、私たちはあなたの電子メールまたは手紙を組織の関連するスタッフに割り当て、苦情の処理速度を速めることができます。たとえば、プライバシーに関する苦情の件名を挿入することができます。
                  <br />
                  私たちは公正な方法で、できるだけ早くあなたの苦情や不満を処理します。
                </li>
              </ol>
            </li>
            <li>
              <h3 className="my-2">利用規約</h3>
              <ol>
                <li>
                  その他の関連ポリシーおよび使用規約を読むこともお勧めします。これには、本ウェブサイトおよびサービスの使用、免責事項、および責任の制限に関する規定が含まれます。
                </li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
  );
};

export default PrivacyPolicyScreen
