import React from "react";
import { useTranslation } from "react-i18next";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Icon, Image, Text, Tag } from "../components";
import { useTheme } from "../config";

export default function ProfileDescription(props: any) {
  const {
    style,
    image,
    styleThumb,
    onPress,
    name,
    subName,
    description,
    tags,
  } = props;
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <TouchableOpacity
      style={[styles.contain, style]}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <Image source={image} style={[styles.thumb, styleThumb]} />
      <View style={{ flex: 1, alignItems: "flex-start" }}>
        <Text headline semibold numberOfLines={1}>
          {name}
        </Text>
        <Text
          body2
          primaryColor
          style={{ marginTop: 3, marginBottom: 3 }}
          numberOfLines={1}
        >
          {subName}
        </Text>
        <Text footnote grayColor numberOfLines={2} style={{ paddingRight: 20 }}>
          {description}
        </Text>
        <View style={styles.listContentService}>
          {tags.map((item: any, index: any) => {
            return (
              <Tag
                key={"service" + index}
                icon={
                  <Icon
                    name={item.icon}
                    size={12}
                    color={colors.accent}
                    solid
                    style={{ marginRight: 5 }}
                  />
                }
                chip
                style={{
                  marginTop: 5,
                  marginRight: 5,
                }}
              >
                {t(`license:${item.name}`)}
              </Tag>
            );
          })}
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    flexDirection: "row",
    alignItems: "center",
  },
  thumb: {
    width: 80,
    height: 80,
    borderRadius: 40,
    marginRight: 10,
  },
  //css Tags
  listContentService: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
