import React, { useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  StyleSheet,
} from "react-native";
import { BaseStyle, BaseColor, Images, useTheme } from "../../config";
import {
  Image,
  Header,
  SafeAreaView,
  Icon,
  ProfileDescription,
  ProfilePerformance,
  Tag,
  Text,
  Card,
  ServiceInfomation,
  SearchItem,
  Button,
  RateDetail,
  CommentItem,
  ServiceItem,
} from "../../components";
import { TabView, TabBar } from "react-native-tab-view";
import { UserData, ReviewData, ServiceData } from "../../fakedata";
import * as Utils from "../../utils";

import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Calendar } from "react-native-calendars";
import Modal from "react-native-modal";

export default function SitterDetail({ navigation, route }: any) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { sitterDetail } = route.params;

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "service", title: t("enum:service") },
    { key: "description", title: t("enum:description") },
    { key: "review", title: t("enum:reviews") },
  ]);
  const [userData] = useState(UserData[0]);

  const [renderCalendar, setRenderCalendar] = useState(true);
  const [markedDates, setMarkedDates] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  // When tab is activated, set what's index value
  const handleIndexChange = (index: any) => setIndex(index);

  // Customize UI tab bar
  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      scrollEnabled
      indicatorStyle={[styles.indicator, { backgroundColor: colors.primary }]}
      style={[styles.tabbar, { backgroundColor: colors.background }]}
      tabStyle={styles.tab}
      inactiveColor={BaseColor.grayColor}
      activeColor={colors.text}
      renderLabel={({ route, focused, color }) => (
        <View style={{ flex: 1, width: 130, alignItems: "center" }}>
          <Text headline semibold={focused} style={{ color }}>
            {route.title}
          </Text>
        </View>
      )}
    />
  );

  // Render correct screen container when tab is activated
  const renderScene = ({ route, jumpTo }: any) => {
    switch (route.key) {
      case "service":
        return <ServicesTab jumpTo={jumpTo} navigation={navigation} />;
      case "description":
        return <DescriptionTab jumpTo={jumpTo} navigation={navigation} />;
      case "review":
        return <ReviewTab jumpTo={jumpTo} navigation={navigation} />;
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Header
        title={t("page:sitter_detail")}
        subTitle={""}
        renderLeft={() => {
          return <Icon name="arrow-left" size={20} color={colors.primary} />;
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
        renderRight={() => {
          return (
            <Text primaryColor style={{ fontSize: 12 }}>
              {t("enum:visit_to_the_depositary")}
            </Text>
          );
        }}
        onPressRight={() => {
          setRenderCalendar(true);
          setModalVisible(true);
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={["right", "left", "bottom"]}
      >
        {/* Calendar */}
        <Modal
          isVisible={modalVisible}
          backdropColor="rgba(0, 0, 0, 0.5)"
          backdropOpacity={1}
          animationIn="fadeIn"
          animationInTiming={600}
          animationOutTiming={600}
          backdropTransitionInTiming={600}
          backdropTransitionOutTiming={600}
        >
          <View
            style={[styles.contentCalendar, { backgroundColor: colors.card }]}
          >
            {renderCalendar && (
              <Calendar
                style={{
                  borderRadius: 8,
                  backgroundColor: colors.card,
                }}
                renderArrow={(direction) => {
                  return (
                    <Icon
                      name={direction == "left" ? "angle-left" : "angle-right"}
                      size={14}
                      color={colors.primary}
                    />
                  );
                }}
                markedDates={markedDates}
                onDayPress={(day) => {}}
                monthFormat={"dd-MM-yyyy"}
                theme={{
                  calendarBackground: colors.card,
                  textSectionTitleColor: colors.text,
                  selectedDayBackgroundColor: colors.primary,
                  selectedDayTextColor: "#ffffff",
                  todayTextColor: colors.primary,
                  dayTextColor: colors.text,
                  textDisabledColor: BaseColor.grayColor,
                  dotColor: colors.primary,
                  selectedDotColor: "#ffffff",
                  arrowColor: colors.primary,
                  monthTextColor: colors.text,
                  textMonthFontWeight: "bold",
                  textDayFontSize: 14,
                  textMonthFontSize: 16,
                  textDayHeaderFontSize: 14,
                }}
              />
            )}
            <View style={{ marginVertical: 20 }}>
              <Text body2 accentColor style={{ marginHorizontal: 15 }}>
                無料の家庭訪問を提供しており、犬の状態を事前に把握し、飼い主に理解してもらうことができます。
              </Text>
            </View>
            <View style={styles.contentActionCalendar}>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(false);
                  // setSelected(props.selected);
                  // onCancel();
                }}
              >
                <Text body1>{t("enum:cancel")}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(false);
                  // onChange(selected);
                }}
              >
                <Text body1 primaryColor>
                  {t("enum:done")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        {/* Calendar */}

        <ProfileDescription
          image={sitterDetail?.image}
          name={sitterDetail?.name}
          subName={"公司：Fluv"} //某某寵物公司
          description={sitterDetail?.location} // 地址
          tags={sitterDetail?.tags}
          style={{ marginTop: 25, paddingHorizontal: 20 }}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 30,
          }}
        >
          <View style={{ flex: 1, paddingLeft: 10, paddingVertical: 5 }}>
            <ProfilePerformance
              flexDirection="row"
              data={userData.performance}
              type="small"
            />
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <TabView
            lazy
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={handleIndexChange}
          />
          <View
            style={[
              styles.contentButtonBottom,
              { borderTopColor: colors.border },
            ]}
          >
            <View>
              <Text caption1 semibold>
                {t("enum:type_care_dog")}
              </Text>
              <Text title3 primaryColor semibold>
                {t("enum:currency")} 4000
              </Text>
            </View>
            <Button onPress={() => navigation.navigate("PreviewReserve")}>
              {t("enum:quick_reserve")}
            </Button>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

function ServicesTab({ navigation }: any) {
  const [services] = useState(ServiceData);
  const [dayTour] = useState([
    {
      id: "1",
      image: Images.services.service1,
      day: "Day 1",
      title: "London - Somme - Paris",
      description:
        "Other hygienic practices that the new hotel — which handles, among other guests, patients seeking medical treatment at the Texas Medical Center — include removing nonessential items like decorative pillows and magazines",
    },
    {
      id: "2",
      image: Images.services.service2,
      day: "Day 2",
      title: "Paris - Burgundy - Swiss Alps",
      description:
        "Other hygienic practices that the new hotel — which handles, among other guests, patients seeking medical treatment at the Texas Medical Center — include removing nonessential items like decorative pillows and magazines",
    },
    {
      id: "3",
      image: Images.services.service3,
      day: "Day 3",
      title: "Swiss Alps - Strasbourg",
      description:
        "Other hygienic practices that the new hotel — which handles, among other guests, patients seeking medical treatment at the Texas Medical Center — include removing nonessential items like decorative pillows and magazines",
    },
    {
      id: "4",
      image: Images.services.service3,
      day: "Day 4",
      title: "Grand Ducal Palace",
      description:
        "Other hygienic practices that the new hotel — which handles, among other guests, patients seeking medical treatment at the Texas Medical Center — include removing nonessential items like decorative pillows and magazines",
    },
  ]);
  const [information] = useState([
    { title: "Location", detail: "Luxembourg" },
    { title: "Duration", detail: "16 Days" },
    { title: "Departure", detail: "08:00" },
    { title: "Price per Participant", detail: "2,199.00 USD" },
    { title: "Group size", detail: "3 - 20 people" },
    { title: "Transportation", detail: "Boat, Bicycle, Car" },
  ]);
  const { colors } = useTheme();
  return (
    <ScrollView>
      <View>
        <Text
          headline
          semibold
          style={{
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          {t("enum:other_service")}
        </Text>
        {/* <Text body2 style={{marginBottom: 10, marginLeft: 20}}>
          Let find out what most interesting things
        </Text> */}
        <FlatList
          contentContainerStyle={{ paddingLeft: 5, paddingRight: 20 }}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={services}
          keyExtractor={(item, index) => item.id}
          renderItem={({ item, index }) => (
            <SearchItem
              grid
              style={[styles.tourItem, { marginLeft: 15 }]}
              onPress={() => {
                navigation.navigate("ServiceDetail");
              }}
              image={item.image}
              name={item.name}
              location={item.location}
              travelTime={item.location}
              startTime={item.startTime}
              price={item.price}
              rate={item.rate}
              rateCount={item.rateCount}
              numReviews={item.numReviews}
              author={item.author}
              tags={item.tags}
            />
          )}
        />
      </View>
      <View style={{ paddingHorizontal: 20 }}>
        {/* {information.map((item, index) => {
          return (
            <View
              style={[
                styles.lineInformation,
                {borderBottomColor: colors.border},
              ]}
              key={'service' + index}>
              <Text body2 grayColor>
                {item.title}
              </Text>
              <Text body2 semibold accentColor>
                {item.detail}
              </Text>
            </View>
          );
        })} */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Text headline semibold>
            {t("enum:service_photo")}
          </Text>
          <TouchableOpacity>
            <Text footnote grayColor>
              {t("enum:show_more")}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.contentImageGird}>
          <View style={{ flex: 4, marginRight: 10 }}>
            <Card image={Images.services.service1}>
              {/* <Text headline semibold whiteColor>
                Dallas
              </Text> */}
            </Card>
          </View>
          <View style={{ flex: 6 }}>
            <View style={{ flex: 1 }}>
              <Card image={Images.services.service2}>
                {/* <Text headline semibold whiteColor>
                  Warsaw
                </Text> */}
              </Card>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
              }}
            >
              <View style={{ flex: 6, marginRight: 10 }}>
                <Card image={Images.services.service4}>
                  {/* <Text headline semibold whiteColor>
                    Yokohama
                  </Text> */}
                </Card>
              </View>
              <View style={{ flex: 4 }}>
                <Card image={Images.services.service5}>
                  {/* <Text headline semibold whiteColor>
                    10+
                  </Text> */}
                </Card>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

function DescriptionTab({ navigation }: any) {
  return (
    <ScrollView>
      <View style={{ paddingHorizontal: 20, marginTop: 20 }}>
        <Text headline semibold style={{ marginTop: 20 }}>
          {/* Title */}
          {t("enum:about_me")}
        </Text>
        {/* Image */}
        <Image
          source={Images.services.service2}
          style={{ height: 120, width: "100%", marginTop: 10 }}
        />
        {/* Description */}
        <Text body2 style={{ marginTop: 10 }}>
          日本ペットシッターサービスは、東京本部、関西分室を中心に北は北海道の札幌から南は九州、沖縄まで、全国126店舗のチェーン展開をしているペットシッターサービスの会社です。毎日350名を超えるペットシッターが、飼い主様に代わってペットのお世話をしています。
          創業以来のペットシッター件数は、全国で80万件を超えました。
          ペットシッターを利用してみたいけど、どんな人が来るのか…不安…
          初めてご利用される方は、皆さん同じ気持ちです。
          日本ペットシッターサービスに登録しているペットシッターは研修を修了した身元の確実な方々で構成されています。また、地域によってはペットシッター店同士が連携を図り、お客様のペット達のお世話をするなど、組織ならではの展開も行っています。
          どうぞ安心してご利用ください。
        </Text>
      </View>
    </ScrollView>
  );
}

function ReviewTab({ navigation }: any) {
  const [refreshing] = useState(false);
  const [rateDetail] = useState({
    point: 4.7,
    maxPoint: 5,
    totalRating: 25,
    data: ["80%", "10%", "10%", "0%", "0%"],
  });
  const [reviewList] = useState(ReviewData);
  const { colors } = useTheme();

  return (
    <FlatList
      contentContainerStyle={{ padding: 20 }}
      refreshControl={
        <RefreshControl
          colors={[colors.primary]}
          tintColor={colors.primary}
          refreshing={refreshing}
          onRefresh={() => {}}
        />
      }
      data={reviewList}
      keyExtractor={(item, index) => item.id}
      ListHeaderComponent={() => (
        <RateDetail
          point={rateDetail.point}
          maxPoint={rateDetail.maxPoint}
          totalRating={rateDetail.totalRating}
          data={rateDetail.data}
        />
      )}
      renderItem={({ item }) => (
        <CommentItem
          style={{ marginTop: 10 }}
          image={item.source}
          name={item.name}
          rate={item.rate}
          date={item.date}
          title={item.title}
          comment={item.comment}
        />
      )}
    />
  );
}

const styles = StyleSheet.create({
  location: {
    flexDirection: "row",
    marginTop: 10,
  },
  contentTag: {
    marginLeft: 20,
    marginTop: 10,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  tabbar: {
    height: 40,
  },
  tab: {
    width: 130,
  },
  indicator: {
    height: 2,
  },
  label: {
    fontWeight: "400",
  },
  contentImageGird: {
    flexDirection: "row",
    height: Utils.scaleWithPixel(160),
    marginTop: 10,
  },
  tourItem: {
    width: 160,
  },
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lineInformation: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    borderBottomWidth: 1,
  },

  // calendar
  contentPickDate: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 10,
  },
  itemPick: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contentCalendar: {
    borderRadius: 8,
    width: "100%",
  },
  contentActionCalendar: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
});
