import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Text, Button, Icon } from "../components";
import Modal from "react-native-modal";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseColor, useTheme } from "../config";
import { useTranslation } from "react-i18next";

interface SelectModalProps {
  visible: boolean;
  options: Array<SelectOption>;
  selected: SelectOption;
  onChange: any;
}

interface SelectOption {
  value: any;
  text: any;
  checked?: boolean;
}

const SelectModal = (props: SelectModalProps) => {
  const { visible, options, selected, onChange } = props;
  const { t } = useTranslation();
  const { colors } = useTheme();
  const cardColor = colors.card;
  const [modalVisible, setModalVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState<SelectOption>(selected);
  const [selectOptions, setSelectOptions] = useState(
    options.map((item: SelectOption): SelectOption => {
      return {
        ...item,
        checked: item?.value === optionSelected.value,
      };
    }),
  );

  const onSelectFilter = (selected: SelectOption) => {
    setSelectOptions(
      options.map((item: SelectOption) => {
        return {
          ...item,
          checked: item?.value == selected.value,
        };
      }),
    );
  };

  const onOpenSelectModal = () => {
    setModalVisible(true);
    setSelectOptions(
      options.map((item: SelectOption) => {
        return {
          ...item,
          checked: item?.value === selected?.value,
        };
      }),
    );
  };

  const onApply = () => {
    const selectOption = selectOptions.filter(
      (item: SelectOption) => item?.checked,
    );
    if (selectOption.length > 0) {
      setOptionSelected(selectOption[0]);
      setModalVisible(false);
      onChange(selectOption[0]);
    }
  };

  useEffect(() => {
    if (visible) {
      onOpenSelectModal();
    }
  }, [visible]);

  return (
    <Modal
      isVisible={modalVisible}
      onSwipeComplete={() => {
        setModalVisible(false);
        setSelectOptions(options);
      }}
      swipeDirection={["down"]}
      style={[styles.bottomModal]}
    >
      <View
        style={[styles.contentFilterBottom, { backgroundColor: cardColor }]}
      >
        <View style={styles.contentSwipeDown}>
          <View style={styles.lineSwipeDown} />
        </View>
        {selectOptions.map((item: SelectOption) => (
          <TouchableOpacity
            style={[
              styles.contentActionModalBottom,
              { borderBottomColor: colors.border },
            ]}
            key={item?.value}
            onPress={() => onSelectFilter(item)}
          >
            <Text body2 semibold primaryColor={item?.checked}>
              {t(item?.text)}
            </Text>
            {item?.checked && (
              <Icon name="check" size={14} color={colors.primary} />
            )}
          </TouchableOpacity>
        ))}
        <Button
          full
          style={{ marginTop: 10, marginBottom: 20 }}
          onPress={() => onApply()}
        >
          {t("enum:select")}
        </Button>
      </View>
    </Modal>
  );
};

export default SelectModal;

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  contentFilterBottom: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 20,
  },
  contentSwipeDown: {
    paddingTop: 10,
    alignItems: "center",
  },
  lineSwipeDown: {
    width: 30,
    height: 2.5,
    backgroundColor: BaseColor.dividerColor,
  },
  contentActionModalBottom: {
    flexDirection: "row",
    paddingVertical: 15,
    justifyContent: "space-between",
    borderBottomWidth: 1,
  },
});
