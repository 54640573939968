import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";
import React, { ReactNode } from "react";
import { BaseColor, FontWeight, Typography, useTheme } from "../../config";

interface TextProps {
  //define style
  header?: boolean;
  title1?: boolean;
  title2?: boolean;
  title3?: boolean;
  headline?: boolean;
  body1?: boolean;
  body2?: boolean;
  callout?: boolean;
  subhead?: boolean;
  footnote?: boolean;
  caption1?: boolean;
  caption2?: boolean;
  overline?: boolean;
  //define font custom
  thin?: boolean;
  ultraLight?: boolean;
  light?: boolean;
  regular?: boolean;
  medium?: boolean;
  semibold?: boolean;
  bold?: boolean;
  heavy?: boolean;
  black?: boolean;
  //custon for text color
  primaryColor?: boolean;
  darkPrimaryColor?: boolean;
  lightPrimaryColor?: boolean;
  accentColor?: boolean;
  grayColor?: boolean;
  dividerColor?: boolean;
  whiteColor?: boolean;
  fieldColor?: boolean;
  //numberOfLines
  ellipsizeMode?: "head" | "middle" | "tail" | "clip" | undefined;
  numberOfLines?: number;
  textAlign?: string;
  //custom style
  style?: StyleProp<TextStyle>;
  children?: ReactNode; // plain text
}

const Roboto = {
  100: "Thin",
  200: "Thin",
  300: "Light",
  400: "Regular",
  500: "Medium",
  600: "Medium",
  700: "Bold",
  800: "Bold",
  900: "Black",
  normal: "Regular",
  bold: "Bold",
};

const Raleway = {
  100: "Thin",
  200: "ExtraLight",
  300: "Light",
  400: "Regular",
  500: "Medium",
  600: "SemiBold",
  700: "Bold",
  800: "ExtraBold",
  900: "Black",
  normal: "Regular",
  bold: "Bold",
};

const Merriweather = {
  100: "Light",
  200: "Light",
  300: "Light",
  400: "Regular",
  500: "Regular",
  600: "Bold",
  700: "Bold",
  800: "Bold",
  900: "Black",
  normal: "Regular",
  bold: "Bold",
};

export default (props: TextProps) => {
  const {
    //props style
    header,
    title1,
    title2,
    title3,
    headline,
    body1,
    body2,
    callout,
    subhead,
    footnote,
    caption1,
    caption2,
    overline,
    // props font
    thin,
    ultraLight,
    light,
    regular,
    medium,
    semibold,
    bold,
    heavy,
    black,
    //custom color
    primaryColor,
    darkPrimaryColor,
    lightPrimaryColor,
    accentColor,
    grayColor,
    dividerColor,
    whiteColor,
    fieldColor,
    //numberOfLines
    numberOfLines,
    ellipsizeMode,
    textAlign,
    //custom
    style,
    //children
    children,
  } = props;
  const { colors } = useTheme();
  let textStyle = StyleSheet.flatten([
    { fontFamily: textAlign },
    header && Typography.header,
    title1 && Typography.title1,
    title2 && Typography.title2,
    title3 && Typography.title3,
    headline && Typography.headline,
    body1 && Typography.body1,
    body2 && Typography.body2,
    callout && Typography.callout,
    subhead && Typography.subhead,
    footnote && Typography.footnote,
    caption1 && Typography.caption1,
    caption2 && Typography.caption2,
    overline && Typography.overline,
    //custom for font
    thin && { fontWeight: FontWeight.thin },
    ultraLight && { fontWeight: FontWeight.ultraLight },
    light && { fontWeight: FontWeight.light },
    regular && { fontWeight: FontWeight.regular },
    medium && { fontWeight: FontWeight.medium },
    semibold && { fontWeight: FontWeight.semibold },
    bold && { fontWeight: FontWeight.bold },
    heavy && { fontWeight: FontWeight.heavy },
    black && { fontWeight: FontWeight.black },
    // default color
    { color: colors.text },
    //custom for color
    primaryColor && { color: colors.primary },
    darkPrimaryColor && { color: colors.primaryDark },
    lightPrimaryColor && { color: colors.primaryLight },
    accentColor && { color: colors.accent },
    grayColor && { color: BaseColor.grayColor },
    dividerColor && { color: BaseColor.dividerColor },
    whiteColor && { color: BaseColor.whiteColor },
    fieldColor && { color: BaseColor.fieldColor },
    style && style,
  ]);
  return (
    <Text
      style={textStyle}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
    >
      {children ?? ""}
    </Text>
  );
};
