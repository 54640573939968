import { Images } from "../config";

export default [
  {
    id: "0",
    user: "千夏",
    message: "そろそろナナと戻りますよ！",
    image: Images.topsitter.sitter1,
    date: "Dec 11, 2018",
    number: "9",
  },
  {
    id: "1",
    user: "梨々香",
    message: "そろそろナナと戻りますよ！",
    image: Images.topsitter.sitter2,
    date: "Dec 11, 2018",
    number: "1",
  },
  {
    id: "2",
    user: "優樹菜",
    message: "そろそろナナと戻りますよ！",
    image: Images.topsitter.sitter3,
    date: "Dec 11, 2018",
    number: "2",
  },
  {
    id: "3",
    user: "日菜子",
    message: "そろそろナナと戻りますよ！",
    image: Images.topsitter.sitter4,
    date: "Dec 11, 2018",
    number: "8",
  },
];
